// ThreadItem.tsx
import {
    Text,
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    Skeleton,
    SkeletonItem,
    Dialog,
    DialogSurface,
    DialogBody,
    DialogActions,
    DialogContent,
    Button,
} from '@fluentui/react-components';
import type { SkeletonProps } from '@fluentui/react-components';
import { useState, useEffect, useRef, useCallback, Fragment } from 'react';

import useAppSelector from '../../Hooks/useAppSelector';
import { ChatThreadModel } from '../../Models/ChatThread';
import {
    useDeleteThreadMutation,
    useGetThreadsQuery,
    usePostThreadMutation,
} from '../../Services/API/Aurora';
import {
    updateNewChatDialogOpenFalse,
    selectChatThreadAction,
    updateLeftOpen,
    setSelectedThreadId,
    setIsCreatingNewThread,
    setIsNewThread,
} from '../../Services/StateManagement/Actions';
import { updateStore } from '../../Services/StateManagement/Utils';
import ChatThreadInteractions from '../ChatThreadInteractions';

import '../../Styles/ChatThread.Module.css';

type Pagination = {
    pageCount: number;
    totalItemCount: number;
    pageNumber: number;
    pageSize: number;
};

type Props = {
    pageNumber: number;
};

const ThreadItemContainer: React.FC<Props & SkeletonProps> = (props) => {
    const { pageNumber } = props;
    const threadId = useAppSelector((store) => store.thread.selectedThreadId);

    const {
        data: threads,
        error: getThreadsError,
        isLoading,
    } = useGetThreadsQuery(pageNumber);

    const [
        deleteThread,
        { isLoading: isDeleteInProgress, isError: isErrorDeletingThread },
    ] = useDeleteThreadMutation();

    const [
        postThread,
        { isLoading: isCreatingNewThread, isError: isErrorCreatingNewThread },
    ] = usePostThreadMutation();

    useEffect(() => {
        updateStore(setIsCreatingNewThread(isCreatingNewThread));
    }, [isCreatingNewThread]);

    const sortedChatItems = (threads ? [...threads.items] : []).sort((a, b) => {
        return (
            new Date(b.timeStamp).getTime() - new Date(a.timeStamp).getTime()
        );
    });
    const [dialogOpen, setDialogOpen] = useState(false);
    const [resolveDialog, setResolveDialog] =
        useState<(value: boolean) => void>();
    const [deleteId, setDeleteId] = useState(-1);

    const confirmDeleteRef = useRef<HTMLButtonElement | null>(null);

    const handlePopover = () => {
        setDialogOpen(true);
        return new Promise<boolean>((resolve) => {
            setResolveDialog(() => resolve);
        });
    };

    const handleDelete = async (args: { id: number; title: string }) => {
        //try {
        setDialogOpen(true);
        const confirm = await handlePopover();
        if (confirm) {
            setDeleteId(args.id);
            deleteThread(args);
            if (threadId === args.id) {
                updateStore(setSelectedThreadId(null));
                postThread();
            }
        }
        //} catch (error) {}
    };

    useEffect(() => {
        if (dialogOpen) {
            confirmDeleteRef?.current?.focus();
        }
    }, [dialogOpen]);

    const handleSelectThread = useCallback((id: number) => {
        updateStore(setIsNewThread(false));
        updateStore(setSelectedThreadId(id));
        updateStore(selectChatThreadAction(id));
        updateStore(updateNewChatDialogOpenFalse());
        //if media is smaller than 900px in width, close the left sidebar
        if (window.innerWidth < 900) {
            updateStore(updateLeftOpen());
        }
    }, []);

    const handleCancelClick = useCallback(() => {
        if (resolveDialog) {
            resolveDialog(false);
        }
        setDialogOpen(false);
    }, [resolveDialog]);
    const handleConfirmClick = useCallback(() => {
        if (resolveDialog) {
            resolveDialog(true);
        }
        setDialogOpen(false);
    }, [resolveDialog]);

    const now = new Date().getTime();

    const getDaysDiff = (chatDate: string) => {
        const chatDateTime = new Date(chatDate).getTime();
        return Math.floor((now - chatDateTime) / (1000 * 60 * 60 * 24));
    };

    const recentChats = sortedChatItems.filter(
        (item) =>
            getDaysDiff(item.timeStamp) <= 7 &&
            !item.isFavorite &&
            !item.isHidden,
    );
    const previousChats = sortedChatItems.filter(
        (item) =>
            getDaysDiff(item.timeStamp) > 7 &&
            getDaysDiff(item.timeStamp) <= 182 &&
            item.isFavorite != true,
    );
    // Sort favorite chats by date (most recent first)

    const favoriteChats = sortedChatItems.filter(
        (item: ChatThreadModel) => item.isFavorite,
    );
    const renderChats = (
        chats: ChatThreadModel[],
        title: string,
        isLast: boolean,
    ) => (
        <div
            style={{
                marginLeft: '0px',
                marginTop: '20px',
            }}
        >
            <Text
                style={{
                    marginLeft: '10px',
                }}
            >
                <b>{title}</b>
            </Text>
            <div
                style={{
                    marginTop: '5px',
                }}
            >
                {chats
                    .filter((c) => !c.isHidden)
                    .map((item: ChatThreadModel, index) =>
                        item.isFavorite != true ? (
                            <ChatThreadInteractions
                                key={`nonfavorites-${item.id}-${index}`}
                                item={item}
                                onSelect={() => handleSelectThread(item.id)}
                                handleDelete={handleDelete}
                                isDeleteInProgress={
                                    isDeleteInProgress && deleteId === item.id
                                }
                                isLast={isLast && index === chats.length - 1}
                            />
                        ) : null,
                    )}
            </div>
        </div>
    );
    const sortedFavoriteChats = [...favoriteChats].sort((a, b) => {
        return a.title.localeCompare(b.title);
    });
    const calculateSkeletonsToFit = () => {
        const threadAreaHeight = window.innerHeight;
        const skeletonHeight = 64;
        const skeletonsToFit = Math.floor(threadAreaHeight / skeletonHeight);
        return skeletonsToFit;
    };
    if (isLoading) {
        const skeletonsCount = calculateSkeletonsToFit();
        return (
            <div>
                <br />
                <Skeleton {...props} animation="pulse">
                    <SkeletonItem
                        style={{
                            width: '120px',
                            backgroundColor: 'var(--colorNeutralBackground1)',
                        }}
                    />
                </Skeleton>
                <br />
                {[...Array(skeletonsCount)].map((_, index) => (
                    <Fragment key={`chat-skeleton-${index}}`}>
                        <Skeleton {...props} animation="pulse">
                            <SkeletonItem
                                style={{
                                    backgroundColor:
                                        'var(--colorNeutralBackground1)',
                                }}
                            />
                        </Skeleton>
                        <br />
                    </Fragment>
                ))}
                <br></br>
            </div>
        );
    }

    if (getThreadsError) {
        return (
            <div>
                Unable to load chat history, please refresh the page and try
                again
            </div>
        );
    }

    const totalDisplayedItems =
        favoriteChats.length + recentChats.length + previousChats.length;
    const totalRecentAndPreviousItems =
        recentChats.length + previousChats.length;

    return (
        <div>
            <Dialog open={dialogOpen}>
                <DialogSurface>
                    <DialogBody
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <div>
                            {' '}
                            <DialogContent
                                style={{
                                    marginTop: '20px',
                                    marginBottom: '20px',
                                    fontSize: '15px',
                                }}
                            >
                                Are you sure you want to delete this chat?
                            </DialogContent>
                        </div>

                        <div>
                            <DialogActions>
                                <Button
                                    onClick={handleCancelClick}
                                    appearance="secondary"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={handleConfirmClick}
                                    appearance="primary"
                                    ref={confirmDeleteRef}
                                >
                                    Confirm
                                </Button>
                            </DialogActions>
                        </div>
                    </DialogBody>
                </DialogSurface>
            </Dialog>

            {sortedFavoriteChats.length > 0 && ( // Render the sorted favorite chats
                <Accordion
                    collapsible
                    defaultOpenItems="1"
                    style={{ marginBottom: '20px' }}
                >
                    <AccordionItem value="1">
                        <AccordionHeader expandIconPosition="end">
                            <div
                                style={{
                                    marginLeft: '-3px',
                                }}
                            >
                                <Text>
                                    <b>Favorite Chats</b>
                                </Text>{' '}
                            </div>
                        </AccordionHeader>
                        <AccordionPanel
                            style={{
                                marginLeft: '0px',
                            }}
                        >
                            {sortedFavoriteChats.map(
                                (item: ChatThreadModel, index) => (
                                    <ChatThreadInteractions
                                        key={`favorites ${item.id}-${index}`}
                                        item={item}
                                        onSelect={() =>
                                            handleSelectThread(item.id)
                                        }
                                        handleDelete={handleDelete}
                                        isDeleteInProgress={
                                            isDeleteInProgress &&
                                            deleteId === item.id
                                        }
                                        isLast={
                                            index === totalDisplayedItems - 1
                                        }
                                    />
                                ),
                            )}
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            )}

            {recentChats.length > 0 &&
                renderChats(
                    recentChats,
                    'Recent Chats',
                    previousChats.length === 0,
                )}
            {previousChats.length > 0 &&
                renderChats(previousChats, 'Previous History', true)}
        </div>
    );
};

export default ThreadItemContainer;
