import { ToastIntent, ToastPosition } from '@fluentui/react-components';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type ToastMessage = {
    title: string;
    position: ToastPosition;
    body?: string;
    intent?: ToastIntent;
};

const initialState: ToastMessage = {
    title: '',
    position: 'bottom',
    body: '',
    intent: 'error',
};

export const ToastMessageSlice = createSlice({
    name: 'toastMessage',
    initialState: initialState,
    reducers: {
        setToastMessage: (state, action: PayloadAction<ToastMessage>) => {
            return { ...state, ...action.payload };
        },
        clearToastMessage: () => {
            return {
                title: '',
                position: 'bottom' as ToastPosition,
                body: '',
                intent: 'error' as ToastIntent,
            };
        },
    },
});
