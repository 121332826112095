import { useMsal } from '@azure/msal-react';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';

import { acquireToken } from 'Helpers/MsalHelper';

export const useAdmin = () => {
    const { instance, accounts } = useMsal();
    const account = accounts[0];
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        async function fetchAdminToken() {
            // You can await here
            const token = await acquireToken(instance, account, [
                process.env.REACT_APP_API_REQUEST_SCOPE!,
            ]);
            if (!token) {
                return;
            }
            const decoded = jwtDecode<{ roles: string[] }>(token);
            if (decoded.roles.includes('Aurora.Admins')) {
                setIsAdmin(true);
            }
        }
        fetchAdminToken();
    }, [instance, account]);

    return isAdmin;
};
