import {
    tokens,
    Dialog,
    DialogSurface,
    DialogBody,
    DialogContent,
    Button,
} from '@fluentui/react-components';
import { DismissFilled } from '@fluentui/react-icons';
import React from 'react';

type ImageDialogProps = {
    isOpen: boolean;
    imageUrl: string | null;
    onClose: () => void;
};

export const ImageDialog = ({
    isOpen,
    imageUrl,
    onClose,
}: ImageDialogProps): JSX.Element => {
    return (
        <>
            {isOpen && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        backdropFilter: 'blur(5px)',
                        zIndex: 999,
                    }}
                />
            )}
            <Button
                icon={<DismissFilled />}
                onClick={onClose}
                style={{
                    position: 'fixed',
                    top: '20px',
                    right: '20px',
                    padding: '5px',
                    zIndex: 1000,
                    display: isOpen ? 'block' : 'none',
                    backgroundColor: tokens.colorNeutralForeground1,
                    color: tokens.colorNeutralForegroundInverted,
                }}
            />
            <Dialog
                modalType="modal"
                open={isOpen}
                onOpenChange={(event, { open }) => (open ? null : onClose())}
            >
                <DialogSurface
                    style={{
                        maxWidth: 'fit-content',
                        backgroundColor: 'transparent',
                    }}
                >
                    <DialogBody>
                        <DialogContent
                            className="custom-scrollbar"
                            style={{
                                position: 'relative',
                                maxWidth: '100vw',
                                maxHeight: '100vh',
                                width: 'auto',
                            }}
                        >
                            {imageUrl && (
                                <img
                                    src={imageUrl}
                                    alt="Preview"
                                    style={{
                                        maxWidth: '100vw',
                                        maxHeight: '100vh',
                                        width: 'auto',
                                        height: 'auto',
                                    }}
                                />
                            )}
                        </DialogContent>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </>
    );
};
