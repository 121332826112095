import { BannerSlice } from '../Slices/BannerSlice';
import { ChatThreadsSlice } from '../Slices/ChatThreadsSlice';
import { ErrorMessageSlice } from '../Slices/ErrorMessageSlice';
import { GlobalStateSlice } from '../Slices/GlobalStateSlice';
import { TermsSlice } from '../Slices/TermsSlice';
import { ThreadSlice } from '../Slices/ThreadSlice';
import { ToastMessageSlice } from '../Slices/ToastMessageSlice';
import { UserInterfaceSlice } from '../Slices/UserInterfaceSlice';
import { UserProfileSlice } from '../Slices/UserProfileSlice';
import { UserSkillSlice } from '../Slices/UserSkillSlice';
import { UserSlice } from '../Slices/UserSlice';

export const {
    setChatThreadsAction,
    newChatThreadAction,
    editChatThreadAction,
    deleteChatThreadAction,
    selectChatThreadAction,
    updateChatThreadTitleAction,
    unselectAllChatThreadsAction,
    updateChatThreadDetailsAction,
    addChatResponsesToChatThreadAction,
    addChatResponseToChatThreadAction,
    setChatHistoryLimitAction,
    setThreadSkillsAction,
    setThreadSkillAction,
    setMessageReactionAction,
    setSelectedFileAction,
    deleteSelectedFileAction,
    addChatResponsesToChatThreadActionERM,
} = ChatThreadsSlice.actions;

export const { updateUserSkills } = UserSkillSlice.actions;
export const { updateTerms } = TermsSlice.actions;
export const { updateBanner, updateBannerIsHidden } = BannerSlice.actions;
export const { updateUserProfileAction, updateUserProfilePictureAction } =
    UserProfileSlice.actions;

export const {
    openDocumentSearch,
    closeDocumentSearch,
    updateRightOpen,
    updateLeftOpen,
    updateSettingsDialogOpen,
    updateNewChatDialogOpenTrue,
    updateNewChatDialogOpenFalse,
    updateChatSend,
    updateReloadThreadsTrue,
    updateReloadThreadsFalse,
    updateCreateChat,
    updateLoadMoreThreadsTrue,
    updateLoadMoreThreadsFalse,
    updateLoadMoreMessages,
    updateTermsPopoverTrue,
    updateTermsPopoverFalse,
    updateERMLoading,
    disableChat,
    enableChat,
    setIsStreamingMessage,
} = UserInterfaceSlice.actions;

export const { updateUserAction, toggleThemeAction } = UserSlice.actions;

export const { setIsHostedInTeamsAction, setAccessToken } =
    GlobalStateSlice.actions;

export const { setErrorMessage, clearErrorMessage } = ErrorMessageSlice.actions;

export const { setToastMessage, clearToastMessage } = ToastMessageSlice.actions;

export const {
    setSelectedThreadId,
    unselectThreadId,
    addLocalFileToThread,
    removeLocalFileFromThread,
    initializeThreadPagination,
    incrementThreadPage,
    setIsRetryingTrue,
    setIsRetryingFalse,
    setIsCreatingNewThread,
    setIsNewThread,
    setIsFetchingMessages,
    setCanFetchMoreMessages,
} = ThreadSlice.actions;
