import { shorthands, makeStyles } from '@fluentui/react-components';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import TermsAndConditions from '../Components/TermsAndConditions';
import ChatContainer from '../Containers/Chat';
import LeftSidebarContainer from '../Containers/LeftSidebar';
import RightSidebarContainer from '../Containers/RightSidebar';
import useTermsAndConditions from '../Hooks/useAlerts';
import useAppSelector from '../Hooks/useAppSelector';

type GridContainerProps = {
    rightOpen: boolean;
};
type HomeProps = {};

const GridContainer = styled.div<GridContainerProps>`
    height: 100vh;
    background: var(--colorNeutralBackground2);
    display: flex;
`;

const Header = styled.div`
    height: 75px; /* Adjust height as needed */
    color: #fff; /* Adjust color as needed */
    display: flex;
    align-items: center;
    padding: 0 20px;
    /* Add more styling for the header here */
`;

const MainContent = styled.div`
    display: flex;
    padding: 0px;
    alignitems: flex-start;
    /* Add more styling for the main content here */
`;

const ToggleButton = styled.button`
    /* Add button styling here */
    background: #444;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
`;
const useStyles = makeStyles({
    root: {
        display: 'flex',
        // backgroundColor: tokens?.colorNeutralBackground2,
        ...shorthands.overflow('hidden'),
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding('16px'),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    darkTintOverlay: {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        backgroundColor: 'rgba(0, 0, 0, .7)',
        zIndex: '1',
    },
});

const Home: React.FC<HomeProps> = () => {
    const styles = useStyles();

    const { getTerms } = useTermsAndConditions();

    useEffect(() => {
        getTerms();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const termsPopover = useAppSelector(
        (store) => store.userInterface.termsPopover,
    );

    return (
        <>
            {termsPopover ? <TermsAndConditions></TermsAndConditions> : null}
            <div
                style={{
                    filter: termsPopover ? 'blur(10px)' : '',
                    position: 'relative',
                }}
            >
                <div
                    className={termsPopover ? styles.darkTintOverlay : ''}
                ></div>

                {/* Your main application content here */}

                <div className={styles.root}>
                    <LeftSidebarContainer />
                    <ChatContainer />
                    <RightSidebarContainer />
                </div>
            </div>
        </>
    );
};

export default Home;
