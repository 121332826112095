import {
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    Drawer,
    DrawerProps,
    Button,
    tokens,
    Text,
    Divider,
    makeStyles,
    Slider,
    shorthands,
    Skeleton,
    SkeletonItem,
    Popover,
    PopoverTrigger,
    PopoverSurface,
    Spinner,
    Card,
    SliderOnChangeData,
} from '@fluentui/react-components';
import { Dismiss24Regular, InfoRegular } from '@fluentui/react-icons';
import * as React from 'react';
import { useCallback, useEffect } from 'react';

import useAppSelector from '../../Hooks/useAppSelector';
import {
    useGetThreadDetailsQuery,
    usePatchThreadChatHistoryLimitMutation,
} from '../../Services/API/Aurora';
import {
    disableChat,
    enableChat,
    updateRightOpen,
} from '../../Services/StateManagement/Actions';
import { updateStore } from '../../Services/StateManagement/Utils';
import SkillCardContainer from '../SkillCardContainer';

type RightSidebarProps = {};
type DrawerType = Required<DrawerProps>['type'];

const useStyles = makeStyles({
    drawerHeader: {
        position: 'relative',
        ...shorthands.padding('24px', '12px', '8px'),
    },
    drawerBody: {
        ...shorthands.padding('0px', '5px', '0px', '8px'),
    },
    divider: {
        position: 'absolute',
        zIndex: 1,
        bottom: 0,
        left: 0,
        right: 0,
    },
    warning: {
        color: tokens.colorStatusWarningForeground1,
    },
    card: {
        minHeight: '20px',
        height: 'fit-content',
        ...shorthands.padding('10px', '10px', '10px', '10px'),
    },
});

const RightSidebar: React.FC<RightSidebarProps> = ({}) => {
    const threadId = useAppSelector((store) => store.thread.selectedThreadId);
    const { data: selectedThread } = useGetThreadDetailsQuery(threadId ?? 0, {
        skip: !threadId,
    });
    const files = selectedThread?.attachments;

    const chatHistoryLimitForThread = selectedThread?.chatHistoryLimit;

    const [patchChatHistoryLimit, { isLoading: isLoadingChatHistoryLimit }] =
        usePatchThreadChatHistoryLimitMutation();

    useEffect(() => {
        if (isLoadingChatHistoryLimit) {
            updateStore(disableChat());
            return;
        }
        updateStore(enableChat());
    }, [isLoadingChatHistoryLimit]);

    const styles = useStyles();
    const [type, setType] = React.useState<DrawerType>('inline');
    //User state
    const chatHistoryLimit = useAppSelector(
        (store) => store.user.chatHistoryLimit,
    );

    const [updatedChatHistoryLimit, setUpdatedChatHistoryLimit] =
        React.useState<number>(chatHistoryLimitForThread ?? chatHistoryLimit);

    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
    const userInterface = useAppSelector((store) => store.userInterface);

    const onMediaQueryChange = React.useCallback(
        ({ matches }: { matches: boolean }) =>
            setType(matches ? 'overlay' : 'inline'),
        [setType],
    );

    React.useEffect(() => {
        if (selectedThread) {
            setUpdatedChatHistoryLimit(selectedThread!.chatHistoryLimit!);
        }
    }, [selectedThread]);

    React.useEffect(() => {
        const match = window.matchMedia('(max-width: 900px)');

        if (match.matches) {
            setType('overlay');
        }

        match.addEventListener('change', onMediaQueryChange);

        return () => match.removeEventListener('change', onMediaQueryChange);
    }, [onMediaQueryChange]);

    //Handlers
    const handleChatHistoryChange = useCallback(
        (limit: number) => {
            patchChatHistoryLimit({
                threadId: threadId ?? 0,
                chatHistoryLimit: limit,
            });
        },
        [patchChatHistoryLimit, threadId],
    );

    const handleDismissSidebar = useCallback(() => {
        updateStore(updateRightOpen());
    }, []);
    const handleContextPopoverInfoTrue = useCallback(
        () => setIsPopoverOpen(true),
        [],
    );
    const handleContextPopoverInfoFalse = useCallback(
        () => setIsPopoverOpen(false),
        [],
    );
    const handleContextSliderTouchEnd = useCallback(() => {
        handleChatHistoryChange(updatedChatHistoryLimit);
    }, [handleChatHistoryChange, updatedChatHistoryLimit]);
    const handleContextSliderChange = useCallback(
        (
            event: React.ChangeEvent<HTMLInputElement>,
            data: SliderOnChangeData,
        ) => setUpdatedChatHistoryLimit(data.value),
        [],
    );
    return (
        <Drawer
            type={type}
            separator
            position="end"
            modalType="non-modal"
            open={userInterface.rightPanelOpen}
            style={{
                background: tokens.colorNeutralBackground3,
                width: type == 'overlay' ? '80%' : '375px',
            }}
        >
            <DrawerHeader className={styles.drawerHeader}>
                <DrawerHeaderTitle
                    action={
                        <Button
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Dismiss24Regular />}
                            onClick={handleDismissSidebar}
                        />
                    }
                >
                    Chat Settings
                </DrawerHeaderTitle>
                <Divider className={styles.divider} />
            </DrawerHeader>

            <DrawerBody
                className={`custom-scrollbar-container ${styles.drawerBody} `}
                style={{ background: 'transparent' }}
            >
                <div className="ThreadSettingsChatHistory">
                    <Text weight="semibold" size={300}>
                        Conversation Context
                    </Text>

                    <Popover open={isPopoverOpen}>
                        <PopoverTrigger>
                            <Button
                                appearance="transparent"
                                icon={<InfoRegular />}
                                style={{
                                    marginRight: '10px',
                                }}
                                onMouseEnter={handleContextPopoverInfoTrue}
                                onMouseLeave={handleContextPopoverInfoFalse}
                            />
                        </PopoverTrigger>
                        <PopoverSurface
                            tabIndex={-1}
                            style={{
                                backgroundColor:
                                    'var(--colorNeutralBackground1)',
                                fontSize: '12px',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                                width: '200px',
                            }}
                        >
                            {
                                'Select the number of past messages to include in each new request. This helps give the model context for new user queries. Setting this number to 10 will include 10 user messages and 10 system responses.'
                            }
                        </PopoverSurface>
                    </Popover>

                    {selectedThread ? (
                        <div className="SettingsMenuChatHistoryFlex">
                            <div
                                onMouseUp={handleContextSliderTouchEnd}
                                onTouchEnd={handleContextSliderTouchEnd}
                            >
                                <Slider
                                    value={updatedChatHistoryLimit}
                                    min={0}
                                    max={15}
                                    onChange={handleContextSliderChange}
                                ></Slider>
                            </div>
                            <div style={{ minWidth: '20px' }}>
                                {updatedChatHistoryLimit}
                            </div>{' '}
                            {isLoadingChatHistoryLimit ? (
                                <>
                                    <Spinner size="tiny" />
                                </>
                            ) : null}
                        </div>
                    ) : (
                        <Skeleton animation="pulse">
                            <SkeletonItem
                                style={{
                                    width: '150px',
                                    height: '20px',
                                    backgroundColor:
                                        'var(--colorNeutralBackground1)',
                                    marginTop: '10px',
                                }}
                            />
                        </Skeleton>
                    )}
                </div>
                <div className="ThreadSettingsSkillsHeader">
                    <div>
                        <Text weight="semibold" size={300}>
                            Skills
                        </Text>
                        {files && files.length > 0 ? (
                            <>
                                <Card
                                    style={{
                                        marginTop: '20px',
                                        paddingBottom: '20px',
                                    }}
                                >
                                    <div className="SkillCard">
                                        <div className="CardHeader">
                                            <div className="CardTitle">
                                                <Text weight="bold">
                                                    Document Inference
                                                </Text>
                                            </div>
                                        </div>
                                        <div className="CardBody">
                                            <Text align="start">
                                                {' '}
                                                When a file is selected, skills
                                                will not be utilized. You must
                                                remove your document to access
                                                skills.
                                            </Text>
                                        </div>
                                    </div>
                                </Card>
                            </>
                        ) : null}
                    </div>

                    {selectedThread &&
                        !selectedThread.skills?.some((s) => s.isSelected) &&
                        (files ?? []).length === 0 && (
                            <Text size={300} className={styles.warning}>
                                You must select at least one skill
                            </Text>
                        )}
                </div>

                {selectedThread ? (
                    files && files.length > 0 ? null : (
                        <SkillCardContainer threadDetails={selectedThread} />
                    )
                ) : (
                    <>
                        <Skeleton
                            animation="pulse"
                            style={{ paddingTop: '10px' }}
                        >
                            <SkeletonItem
                                style={{
                                    height: '120px',
                                    width: '210px',
                                    backgroundColor:
                                        'var(--colorNeutralBackground1)',
                                    marginTop: '10px',
                                }}
                            />
                        </Skeleton>

                        <Skeleton animation="pulse">
                            <SkeletonItem
                                style={{
                                    height: '120px',
                                    width: '210px',
                                    backgroundColor:
                                        'var(--colorNeutralBackground1)',
                                    marginTop: '10px',
                                }}
                            />
                        </Skeleton>

                        <Skeleton animation="pulse">
                            <SkeletonItem
                                style={{
                                    height: '120px',
                                    width: '210px',
                                    backgroundColor:
                                        'var(--colorNeutralBackground1)',
                                    marginTop: '10px',
                                }}
                            />
                        </Skeleton>

                        <Skeleton animation="pulse">
                            <SkeletonItem
                                style={{
                                    height: '120px',
                                    width: '210px',
                                    backgroundColor:
                                        'var(--colorNeutralBackground1)',
                                    marginTop: '10px',
                                }}
                            />
                        </Skeleton>
                    </>
                )}
            </DrawerBody>
        </Drawer>
    );
};

export default RightSidebar;
