import { Spinner, tokens, Button } from '@fluentui/react-components';
import { DismissFilled } from '@fluentui/react-icons';
import { useCallback, useState } from 'react';

import { AttachedFile } from 'Models/ChatThread';
import {
    useDownloadThreadAttachmentQuery,
    useRemoveThreadAttachmentMutation,
} from 'Services/API/Aurora';

import { ImageDialog } from '../ImageDialog';

type Props = {
    attachmentId: number;
    name: string;
    threadId?: number;
    isMessage?: boolean;
    url?: string;
    role: string;
};

export const ImageThumbnail = ({
    threadId,
    isMessage,
    attachmentId,
    name,
    url,
    role,
}: Props): JSX.Element => {
    const file: AttachedFile = {
        type: undefined,
        id: attachmentId ?? 0,
        threadId: threadId ?? 0,
        name: name,
        contentType: '',
        url: url ?? undefined,
        role,
    };

    const [isHovered, setIsHovered] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogImageUrl, setDialogImageUrl] = useState<string | null>(null);
    const [removeThreadAttachment, { isLoading: isRemovingAttachment }] =
        useRemoveThreadAttachmentMutation();

    const { data: blobUrl, isLoading: isLoadingImage } =
        useDownloadThreadAttachmentQuery(
            {
                threadId: threadId ?? 0,
                attachmentId: file.id,
                isResponseSupplement: file.role === 'Aurora',
            },
            {
                skip:
                    !threadId ||
                    (Boolean(file.url) && file.url?.startsWith('http')) ||
                    attachmentId < 0, // temp attachment id for cache is negative
            },
        );

    const handleClick = useCallback(() => {
        setDialogImageUrl(blobUrl ?? file.url ?? null);
        setIsDialogOpen(true);
    }, [blobUrl, file.url]);

    const handleIsHoveredTrue = useCallback(() => setIsHovered(true), []);
    const handleIsHoveredFalse = useCallback(() => setIsHovered(false), []);
    const handleDismiss = useCallback(
        (e: React.MouseEvent<SVGElement, MouseEvent>) => {
            e.preventDefault();
            e.stopPropagation();
            removeThreadAttachment({
                threadId: threadId ?? 0,
                attachmentId: file.id ?? 0,
            });
        },
        [file.id, removeThreadAttachment, threadId],
    );
    return (
        <>
            <div
                style={{
                    marginBottom: '5px',
                    backgroundColor: 'var(--colorNeutralBackground5Hover)',
                    backgroundImage: `url(${blobUrl ?? file.url}`,
                    border: '1px solid var(--colorNeutralBackground3Hover)',
                    height: isMessage ? '200px' : '70px',
                    width: isMessage ? '200px' : '70px',
                    maxWidth: '200px',
                    maxHeight: '200px',
                    position: 'relative',
                    backgroundSize: 'cover',
                    borderRadius: '1rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                }}
                onClick={handleClick}
                rel="noopener noreferrer"
                onMouseOver={handleIsHoveredTrue}
                onMouseLeave={handleIsHoveredFalse}
            >
                {(isLoadingImage || isRemovingAttachment) && (
                    <Spinner size="small" />
                )}
                {!isMessage && (blobUrl || file.url) && (
                    <div
                        style={{
                            backgroundColor: isHovered
                                ? tokens.colorNeutralForeground3Hover
                                : tokens.colorNeutralBackground1,
                            borderRadius: '9999px',
                            border: tokens.colorNeutralBackgroundInvertedDisabled,
                            position: 'absolute',
                            top: -8,
                            right: -8,
                            fontSize: '16px',
                            width: '20px',
                            height: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <DismissFilled
                            onClick={handleDismiss}
                            color={
                                isHovered
                                    ? tokens.colorNeutralBackground1
                                    : tokens.colorNeutralForeground2
                            }
                        />
                    </div>
                )}
            </div>
            <ImageDialog
                isOpen={isDialogOpen}
                imageUrl={dialogImageUrl}
                onClose={() => setIsDialogOpen(false)}
            />
        </>
    );
};
