import {
    makeStyles,
    Body1,
    shorthands,
    Card,
    CardHeader,
    CardPreview,
    Link,
    Button,
    Popover,
    PopoverTrigger,
    PopoverSurface,
    Persona,
    Spinner,
} from '@fluentui/react-components';
import {
    HistoryFilled,
    ErrorCircleRegular,
    ArrowCounterclockwiseFilled,
} from '@fluentui/react-icons';
import React from 'react';

import ErrorDialog from 'Components/ErrorMessage';
import { FileCard } from 'Components/FileCard';
import { ImageThumbnail } from 'Components/ImageThumbnail';
import useAppSelector from 'Hooks/useAppSelector';
import { ChatResponseApiResource } from 'Models/ChatThread';
import { usePostMessageMutation } from 'Services/API/Aurora';
import {
    setIsRetryingFalse,
    setIsRetryingTrue,
} from 'Services/StateManagement/Actions';
import { updateStore } from 'Services/StateManagement/Utils';
import { getFileIcon, isImageFile } from 'Utils/index';

const useStyles = makeStyles({
    historyIcon: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        color: 'var(--colorNeutralForeground1)', // Or any other color you want for the icon
    },
    card: {
        ...shorthands.margin('auto'),
        marginBottom: '15px',
        backgroundColor: 'var(--colorNeutralBackground2Hover)',
    },
    CardPreview: {
        paddingBottom: '40px',
    },
    header2: {
        width: '300px',
        marginLeft: '50px',
        backgroundColor: 'var(--colorNeutralBackground2)',
        paddingLeft: '7px',
        paddingRight: '7px',
        paddingTop: '7px',
        paddingBottom: '7px',
    },
});
type References = {
    location?: string;
    name: string;
    number: number;
    url?: string | null;
    attachmentId: number | null;
    contentUrl?: string;
    type?: string;
};

type ContentItem = {
    type: 'text' | 'code' | 'link' | 'error';
    language?: string;
    value: string;
    url?: string;
};

// Define a type for the props expected by the UserChatCardsComponent
type UserChatCardsProps = {
    message: ChatResponseApiResource;
    id: number;
    content: ContentItem[];
    windowWidth: number;
    inHistory: boolean;
    messageDate: string;
    references?: References[];
    didError?: boolean;
};

export const UserChatCardComponent: React.FC<UserChatCardsProps> = ({
    message,
    content,
    windowWidth,
    inHistory,
    messageDate,
    references,
    id,
    didError,
}) => {
    const styles = useStyles();
    const [isChatHistory, setHistory] = React.useState(inHistory);
    const threadId = useAppSelector((store) => store.thread.selectedThreadId);
    const files = useAppSelector((store) => store.thread.files[threadId ?? 0]);
    const [postMessage, { isLoading: isPostingMessage }] =
        usePostMessageMutation();

    // State to control the visibility of the popover
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

    React.useEffect(() => {
        if (isPostingMessage) {
            updateStore(setIsRetryingTrue({ threadId: threadId }));
            return;
        }
        updateStore(setIsRetryingFalse({ threadId: threadId }));
    }, [isPostingMessage, threadId]);

    React.useEffect(() => {
        setHistory(inHistory);
    }, [inHistory]);

    const handleRetry = () => {
        if (isPostingMessage || !message.retryReferenceId) {
            return;
        }

        postMessage({
            threadId: threadId ?? 0,
            message: message.content[0].value,
            isRetry: true,
            retryReferenceId: message.retryReferenceId,
        });
    };

    const renderAction = (
        isChatHistory: boolean,
        didMessageSendError?: boolean,
    ) => {
        if (!isChatHistory && !didMessageSendError) {
            return null;
        }
        if (didMessageSendError) {
            return (
                <Popover open={isPopoverOpen}>
                    <PopoverTrigger>
                        <Button
                            appearance="transparent"
                            icon={<ErrorCircleRegular color={'#d93e26'} />}
                            style={{
                                marginRight: '10px',
                            }}
                            onMouseEnter={() => setIsPopoverOpen(true)}
                            onMouseLeave={() => setIsPopoverOpen(false)}
                        />
                    </PopoverTrigger>
                    <PopoverSurface
                        tabIndex={-1}
                        style={{
                            backgroundColor: 'var(--colorNeutralBackground1)',
                            fontSize: '12px',
                            paddingBottom: '5px',
                        }}
                    >
                        Could not send message
                    </PopoverSurface>
                </Popover>
            );
        }
        return (
            <Popover open={isPopoverOpen}>
                <PopoverTrigger>
                    <Button
                        appearance="transparent"
                        icon={<HistoryFilled />}
                        style={{
                            marginRight: '10px',
                        }}
                        onMouseEnter={() => setIsPopoverOpen(true)}
                        onMouseLeave={() => setIsPopoverOpen(false)}
                    />
                </PopoverTrigger>
                <PopoverSurface
                    tabIndex={-1}
                    style={{
                        backgroundColor: 'var(--colorNeutralBackground1)',
                        fontSize: '12px',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                    }}
                >
                    Included in history
                </PopoverSurface>
            </Popover>
        );
    };

    const userProfile = useAppSelector((store) => store.userProfile);
    const renderContent = (content: ContentItem[]) => {
        return content.map((item, index) => (
            <div key={index}>
                {/* User can only have text or links as chat content */}
                {item.type === 'text' && (
                    <div style={{ whiteSpace: 'pre-wrap' }}>{item.value}</div>
                )}

                {item.type === 'link' && item.url && (
                    <Link
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {item.value}
                    </Link>
                )}

                {didError && (
                    <>
                        <div style={{ height: '24px' }} />
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Button onClick={handleRetry}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {isPostingMessage && (
                                        <Spinner size={'extra-tiny'} />
                                    )}
                                    {!isPostingMessage && (
                                        <>
                                            <ArrowCounterclockwiseFilled
                                                fontSize={20}
                                                style={{
                                                    paddingRight: '4px',
                                                }}
                                            />
                                            {'Retry'}
                                        </>
                                    )}
                                </div>
                            </Button>
                            <ErrorDialog>
                                <Link
                                    style={{
                                        color: '#d93e26',
                                        fontSize: 'small',
                                    }}
                                >
                                    {'> Error Details'}
                                </Link>
                            </ErrorDialog>
                        </div>
                    </>
                )}
            </div>
        ));
    };
    const renderReferences = (references?: References[]) => {
        if (references && references.length > 0 && references[0].name !== '') {
            return (
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '20px',
                    }}
                >
                    {references.map((item, index) => {
                        if (!item.attachmentId || !threadId) {
                            return;
                        }
                        if (isImageFile(item.name)) {
                            return (
                                <ImageThumbnail
                                    key={item.attachmentId}
                                    attachmentId={item.attachmentId}
                                    name={item.name}
                                    url={item.url ?? undefined}
                                    threadId={threadId ?? undefined}
                                    role="User"
                                    isMessage
                                />
                            );
                        }
                        return (
                            <FileCard
                                threadId={threadId}
                                name={item.name}
                                url={item.url ?? undefined}
                                id={item.attachmentId}
                                index={index}
                                disableDismissButton={true}
                            />
                        );
                    })}
                </div>
            );
        }
    };
    const renderDate = (content: ContentItem[]) => {
        const currentDate = new Date();
        const messageDateObj = new Date(messageDate);
        const isWithinPastWeek =
            currentDate.getTime() - messageDateObj.getTime() <=
            7 * 24 * 60 * 60 * 1000;

        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '10px',
                }}
            >
                <div
                    style={{
                        marginBottom: '10px',
                    }}
                >
                    {isWithinPastWeek &&
                    currentDate.toDateString() === messageDateObj.toDateString()
                        ? 'Today'
                        : isWithinPastWeek
                          ? messageDateObj.toLocaleString('en-US', {
                                weekday: 'long',
                            })
                          : messageDateObj.toLocaleString('en-US', {
                                month: 'long',
                                day: 'numeric',
                                year: 'numeric',
                            })}
                    <> </>
                    {messageDateObj.toLocaleString('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true,
                    })}
                </div>
            </div>
        );
    };
    if (message.isLoading) {
        return <></>;
    }
    return (
        <>
            {renderDate(content)}
            <Card
                className={styles.card}
                style={{
                    width: '100%',
                    position: 'relative',
                }}
            >
                <CardHeader
                    image={
                        <Persona
                            size="extra-large"
                            avatar={{
                                image: { src: userProfile.profilePictureUrl },
                            }}
                            name={userProfile.name} // This is used to generate the initials
                            primaryText=""
                            style={{ width: '40px' }}
                        />
                    }
                    header={
                        <Body1 style={{ marginTop: '10px' }}>
                            <b>{userProfile.name}</b>
                        </Body1>
                    }
                    action={renderAction(isChatHistory, didError)}
                />

                <CardPreview
                    className={styles.CardPreview}
                    style={{
                        paddingRight: windowWidth < 900 ? '20px' : '60px',
                        paddingLeft: windowWidth < 900 ? '20px' : '60px',
                    }}
                >
                    {renderContent(content)}
                    <div style={{ height: '12px' }} />
                    {renderReferences(references)}
                </CardPreview>
            </Card>
        </>
    );
};

export default UserChatCardComponent;
