import { ERROR_MESSAGE } from 'Constants';
import { setToastMessage } from 'Services/StateManagement/Actions';

import { auroraApi, AuroraEndpointBuilder } from '..';

export const deleteThread = (builder: AuroraEndpointBuilder) =>
    builder.mutation<void, { id: number; title: string }>({
        query: (body) => ({
            url: '/v1/Threads/DeleteThread',
            method: 'DELETE',
            body,
        }),
        async onQueryStarted(
            args: { id: number; title: string },
            { dispatch, queryFulfilled },
        ) {
            try {
                await queryFulfilled;
                dispatch(
                    auroraApi.util.updateQueryData(
                        'getThreads',
                        0,
                        (draft: any) => {
                            const deletedThreadIndex = draft.items.findIndex(
                                (item: { id: number }) => item.id === args.id,
                            );
                            if (deletedThreadIndex >= 0) {
                                draft.items.splice(deletedThreadIndex, 1);
                            }
                        },
                    ),
                );
                dispatch(
                    setToastMessage({
                        title: 'Thread deleted successfully.',
                        body: '',
                        position: 'top-start',
                        intent: 'success',
                    }),
                );
            } catch (e) {
                dispatch(
                    setToastMessage({
                        title: ERROR_MESSAGE.ThreadDelete,
                        position: 'top-start',
                    }),
                );
            }
        },
        invalidatesTags: ['Threads', 'ThreadDetails'],
    });
