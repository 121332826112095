// ChatThreadInteractions.tsx
import {
    Button,
    Input,
    Card,
    Text,
    ProgressBar,
} from '@fluentui/react-components';
import {
    EditRegular,
    DeleteRegular,
    CheckmarkRegular,
    DismissRegular,
    CheckmarkFilled,
    EditFilled,
    DeleteFilled,
    DismissFilled,
    StarRegular,
    StarFilled,
} from '@fluentui/react-icons';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import '../../Styles/ChatThread.Module.css';

import useAppSelector from '../../Hooks/useAppSelector';
import { ChatThreadModel } from '../../Models/ChatThread';
import { usePatchThreadMutation } from '../../Services/API/Aurora';

type ChatThreadInteractionsProps = {
    item: ChatThreadModel;
    onSelect: () => void;
    handleDelete: ({ id, title }: { id: number; title: string }) => void;
    isDeleteInProgress: boolean;
    isLast: boolean;
};

const ChatThreadInteractions: React.FC<ChatThreadInteractionsProps> = ({
    item,
    onSelect,
    handleDelete,
    isDeleteInProgress,
    isLast,
}) => {
    const threadId = useAppSelector((store) => store.thread.selectedThreadId);
    const [
        patchThread,
        { isError: isPatchThreadError, isLoading: isPatchThreadLoading },
    ] = usePatchThreadMutation();
    const [isHovering, setIsHovering] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [itemName, setItemName] = useState(item.title);
    const [itemFavorite, setItemFavorite] = useState(item.isFavorite);

    const handleMouseEnter = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        setIsHovering(true);
    }, []);

    const handleMouseLeave = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        setIsHovering(false);
    }, []);
    const [isEditHovered, setIsEditHovered] = React.useState(false);
    const [isDeleteHovered, setIsDeleteHovered] = React.useState(false);
    const [isCheckmarkHovered, setIsCheckmarkHovered] = React.useState(false);
    const [isDismissHovered, setIsDismissHovered] = React.useState(false);
    const [isMoreHovered, setIsMoreHovered] = React.useState(false);

    const startEdit = useCallback(() => {
        setIsEditing(true);
    }, []);

    const stopEdit = useCallback(() => {
        setIsEditing(false);
    }, []);

    const handleEditChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = e.target.value;
            if (inputValue.length <= 50) {
                setItemName(inputValue);
            }
        },
        [],
    );

    const handleEditConfirm = useCallback(() => {
        patchThread({ id: item.id, title: itemName, isFavorite: itemFavorite });
        stopEdit();
    }, [item.id, itemFavorite, itemName, patchThread, stopEdit]);

    const handleCancel = useCallback(() => {
        setItemName(item.title);
        stopEdit();
    }, [item.title, stopEdit]);

    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
                handleEditConfirm();
            }
            if (e.key === 'Escape') {
                stopEdit();
            }
        },

        [handleEditConfirm, stopEdit],
    );
    const prevItemFavorite = useRef(itemFavorite);

    useEffect(() => {
        if (prevItemFavorite.current == !itemFavorite) {
            // ItemFavorite changed from true to false
            handleEditConfirm();
        }

        prevItemFavorite.current = itemFavorite;
    }, [handleEditConfirm, itemFavorite]);

    useEffect(() => {
        setItemName(item.title);
    }, [item]);

    const handleCheckmarkHoveredTrue = useCallback(
        () => setIsCheckmarkHovered(true),
        [],
    );
    const handleCheckmarkHoveredFalse = useCallback(
        () => setIsCheckmarkHovered(false),
        [],
    );
    const handleEditConfirmClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            handleEditConfirm();
        },
        [handleEditConfirm],
    );
    const handleDismissHoveredTrue = useCallback(
        () => setIsDismissHovered(true),
        [],
    );
    const handleDismissHoveredFalse = useCallback(
        () => setIsDismissHovered(false),
        [],
    );
    const handleDismissClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
            event.stopPropagation();
            handleCancel();
        },
        [handleCancel],
    );
    const handleEditHoveredTrue = useCallback(() => setIsEditHovered(true), []);
    const handleEditHoveredFalse = useCallback(
        () => setIsEditHovered(false),
        [],
    );
    const handleEditClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
            event.stopPropagation();
            startEdit();
        },
        [startEdit],
    );
    const handleDeleteHoveredTrue = useCallback(
        () => setIsDeleteHovered(true),
        [],
    );
    const handleDeleteHoveredFalse = useCallback(
        () => setIsDeleteHovered(false),
        [],
    );
    const handleDeleteClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
            event.stopPropagation();
            handleDelete({
                id: item.id,
                title: item.title,
            });
        },
        [handleDelete, item.id, item.title],
    );
    const handleFavoriteClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
            event.stopPropagation();
            setItemFavorite(!itemFavorite);
        },
        [itemFavorite],
    );
    const handleMoreHoveredTrue = useCallback(() => setIsMoreHovered(true), []);
    const handleMoreHoveredFalse = useCallback(
        () => setIsMoreHovered(false),
        [],
    );
    return (
        <>
            <div
                className={`ChatThreadInteractions `}
                style={{ position: 'relative' }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <Card
                    appearance="subtle"
                    onClick={onSelect}
                    style={{
                        height: '36px', //edits hover height
                        width: '100%', //edits hover width
                        backgroundColor:
                            item.id === threadId
                                ? 'var(--colorNeutralBackground1)'
                                : isHovering
                                  ? 'var(--colorNeutralBackground2)'
                                  : 'transparent',
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius:
                            item.id === threadId
                                ? '10px'
                                : isHovering
                                  ? '10px'
                                  : '0',
                        paddingLeft: '10px',
                        flexDirection: 'row', // makes the input left-oriented
                        borderLeft:
                            item.id === threadId
                                ? '2px solid var(--colorBrandForeground1)'
                                : 'none',
                    }}
                >
                    {isEditing ? (
                        <div
                            style={{
                                width: '100%',
                            }}
                        >
                            <div>
                                <Input
                                    value={itemName}
                                    onChange={handleEditChange}
                                    onKeyDown={handleKeyDown}
                                    autoFocus
                                    style={{
                                        width: '75%', // makes input left-aligned
                                        textAlign: 'left',
                                        border: '1px solid #ccc',
                                    }}
                                />
                            </div>
                            <div className="editButtonGroup">
                                <Button
                                    icon={
                                        isCheckmarkHovered ? (
                                            <CheckmarkFilled />
                                        ) : (
                                            <CheckmarkRegular />
                                        )
                                    }
                                    onMouseEnter={handleCheckmarkHoveredTrue}
                                    onMouseLeave={handleCheckmarkHoveredFalse}
                                    onClick={handleEditConfirmClick}
                                    style={{
                                        padding: '5px',
                                        border: 'none',
                                        color: 'var(--colorPaletteGreenForeground1)',
                                        cursor: 'pointer',
                                        background: 'transparent',
                                    }}
                                />
                                <Button
                                    icon={
                                        isDismissHovered ? (
                                            <DismissFilled />
                                        ) : (
                                            <DismissRegular />
                                        )
                                    }
                                    onMouseEnter={handleDismissHoveredTrue}
                                    onMouseLeave={handleDismissHoveredFalse}
                                    onClick={handleDismissClick}
                                    style={{
                                        padding: '5px',
                                        border: 'none',
                                        color: 'var(--colorPaletteRedForeground1)',

                                        background: 'transparent',
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div
                            style={{
                                width: '100%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                            title={itemName}
                        >
                            <Text
                                style={{
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {itemName}
                            </Text>
                        </div>
                    )}
                    {isHovering && !isEditing && (
                        <div className="hoverActions">
                            <Button
                                icon={
                                    isEditHovered ? (
                                        <EditFilled />
                                    ) : (
                                        <EditRegular />
                                    )
                                }
                                onMouseEnter={handleEditHoveredTrue}
                                onMouseLeave={handleEditHoveredFalse}
                                onClick={handleEditClick}
                                style={{
                                    padding: '5px',
                                    border: 'none',
                                    background: 'transparent',
                                }}
                            />
                            <Button
                                icon={
                                    isDeleteHovered ? (
                                        <DeleteFilled />
                                    ) : (
                                        <DeleteRegular />
                                    )
                                }
                                onMouseEnter={handleDeleteHoveredTrue}
                                onMouseLeave={handleDeleteHoveredFalse}
                                onClick={handleDeleteClick}
                                style={{
                                    padding: '5px',
                                    border: 'none',
                                    background: 'transparent',
                                }}
                            />
                            <Button
                                icon={
                                    isMoreHovered || itemFavorite ? (
                                        <StarFilled />
                                    ) : (
                                        <StarRegular />
                                    )
                                }
                                onClick={handleFavoriteClick}
                                onMouseEnter={handleMoreHoveredTrue}
                                onMouseLeave={handleMoreHoveredFalse}
                                style={{
                                    padding: '5px',
                                    border: 'none',
                                    background: 'transparent',
                                }}
                            />
                        </div>
                    )}
                </Card>
                <ProgressBar
                    value={
                        isDeleteInProgress || isPatchThreadLoading
                            ? undefined
                            : 1
                    }
                    thickness="large"
                    style={{
                        borderRadius: '4px',
                        marginTop: '1px',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        display:
                            isDeleteInProgress || isPatchThreadLoading
                                ? 'block'
                                : 'none',
                    }}
                />
            </div>
        </>
    );
};

export default ChatThreadInteractions;
