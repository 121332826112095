import { createSlice } from '@reduxjs/toolkit';

import { UserInterface } from '../../../Models/UserInterface';

const isMobileView = window.innerWidth <= 900;
const initialState: UserInterface = {
    mobileView: isMobileView,
    documentSearchOpen: false,
    rightPanelOpen: !isMobileView,
    leftPanelOpen: !isMobileView,
    settingsDialogOpen: false,
    newChatDialogOpen: true,
    chatSend: false,
    reloadThreads: false,
    createChat: true,
    loadMoreThreads: false,
    loadMoreMessages: false,
    refreshSelectedThread: false,
    termsPopover: false,
    ERMLoading: false,
    bannerOpen: true,
    isChatDisabled: false,
    isStreamingMessage: false,
};

export const UserInterfaceSlice = createSlice({
    name: 'userInterface',
    initialState: initialState,
    reducers: {
        setIsStreamingMessage: (state, action) => {
            state.isStreamingMessage = action.payload;
        },
        openDocumentSearch: (state) => {
            state.documentSearchOpen = true;
        },
        closeDocumentSearch: (state) => {
            state.documentSearchOpen = false;
        },
        updateRightOpen: (state) => {
            state.rightPanelOpen = !state.rightPanelOpen;
        },
        updateLeftOpen: (state) => {
            state.leftPanelOpen = !state.leftPanelOpen;
        },

        updateSettingsDialogOpen: (state) => {
            state.settingsDialogOpen = !state.settingsDialogOpen;
        },
        updateNewChatDialogOpenTrue: (state) => {
            if (state.newChatDialogOpen === false) {
                state.newChatDialogOpen = true;
                state.createChat = true;
                state.refreshSelectedThread = true;
            }
            if (window.innerWidth <= 900) {
                // If the click is outside the sidebar, dispatch an action to close it
                state.leftPanelOpen = false;
            }
        },
        updateNewChatDialogOpenFalse: (state) => {
            state.newChatDialogOpen = false;
            state.refreshSelectedThread = false;
        },
        updateChatSend: (state) => {
            state.chatSend = !state.chatSend;
        },
        updateReloadThreadsTrue: (state) => {
            state.reloadThreads = true;
        },
        updateReloadThreadsFalse: (state) => {
            state.reloadThreads = false;
        },
        updateCreateChat: (state) => {
            state.createChat = !state.createChat;
        },
        updateLoadMoreThreadsTrue: (state) => {
            state.loadMoreThreads = true;
        },
        updateLoadMoreThreadsFalse: (state) => {
            state.loadMoreThreads = false;
        },
        updateLoadMoreMessages: (state) => {
            state.loadMoreMessages = !state.loadMoreMessages;
        },
        updateTermsPopoverTrue: (state) => {
            state.termsPopover = true;
        },
        updateTermsPopoverFalse: (state) => {
            state.termsPopover = false;
        },
        updateERMLoading: (state) => {
            state.ERMLoading = !state.ERMLoading;
        },
        disableChat: (state) => {
            state.isChatDisabled = true;
        },
        enableChat: (state) => {
            state.isChatDisabled = false;
        },
    },
});

export default UserInterfaceSlice.reducer;
