import { z } from 'zod';
//User zod schema
export const BannerModelSchema = z.object({
    id: z.number().nullable(),
    message: z.string(),
    isHidden: z.boolean(),
});

//Export banner model
export type Banner = z.infer<typeof BannerModelSchema>;

export const AdminBannerModelSchema = z.object({
    id: z.number(),
    message: z.string(),
    isActive: z.boolean(),
});

export type AdminBanner = z.infer<typeof AdminBannerModelSchema>;
