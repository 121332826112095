import { ERROR_MESSAGE } from 'Constants';
import { setToastMessage } from 'Services/StateManagement/Actions';

import { auroraApi, AuroraEndpointBuilder } from '../..';

export const setActiveBannerId = (builder: AuroraEndpointBuilder) =>
    builder.mutation<void, { id: number | null; message: string }>({
        query: ({ id }) => ({
            url: '/v1/TermsConditions/SetActiveBannerId',
            method: 'PUT',
            body: { id },
        }),
        async onQueryStarted(banner, { dispatch, queryFulfilled }) {
            const bannerUpdate = dispatch(
                auroraApi.util.updateQueryData(
                    'getActiveBanner',
                    undefined,
                    (_draft) => {
                        return {
                            id: banner.id,
                            message: banner.message,
                            isHidden: false,
                        };
                    },
                ),
            );
            try {
                await queryFulfilled;
            } catch {
                bannerUpdate.undo();
                dispatch(
                    setToastMessage({
                        title: ERROR_MESSAGE.BannersUpdate,
                        position: 'top',
                    }),
                );
            }
        },
    });
