import {
    makeStyles,
    Body1,
    Button,
    shorthands,
    Card,
    CardHeader,
    CardPreview,
    Divider,
    Text,
    Popover,
    PopoverTrigger,
    PopoverSurface,
} from '@fluentui/react-components';
import {
    DocumentCopyRegular,
    CheckboxCheckedRegular,
    HistoryFilled,
} from '@fluentui/react-icons';
import * as React from 'react';
import { useState, useRef, useCallback } from 'react';

import { FileCard } from 'Components/FileCard';
import { ImageThumbnail } from 'Components/ImageThumbnail';
import useAppSelector from 'Hooks/useAppSelector';
import { isImageFile } from 'Utils/index';

import {
    ChatResponseContentApiResource,
    ChatResponseApiResource,
    AttachedFile,
} from '../../Models/ChatThread';
import ChatCardResources from '../ChatCardResources';
import MarkdownViewer from '../MarkdownViewer';

const useStyles = makeStyles({
    historyIcon: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        color: 'var(--colorNeutralForeground1)',
    },
    card: {
        ...shorthands.margin('auto'),

        display: 'flex',
        justifyContent: 'center',
        marginBottom: '15px',
        backgroundColor: 'var(--colorNeutralBackground1)',
    },
    CardPreview: {},
    header2: {
        width: '300px',
        marginLeft: '50px',
        backgroundColor: 'var(--colorNeutralBackground2)',
        paddingLeft: '7px',
        paddingRight: '7px',
        paddingTop: '7px',
        paddingBottom: '7px',
    },
    refHeader: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
    },
    copyButton: {
        position: 'absolute', // Position absolute for the button
        top: '10px', // Distance from the top of the container
        right: '10px', // Distance from the right of the container
        zIndex: 1, // Ensure the button is above the code block
    },
});

type AuroraChatCardsProps = {
    message: ChatResponseApiResource;
    windowWidth: number;
    inHistory: boolean;
};

type References = {
    location?: string;
    name: string;
    number: number;
    url?: string | null;
    attachmentId: number | null;
    contentUrl?: string;
    type?: string;
    role?: string;
};

export const AuroraChatCardsComponent: React.FC<AuroraChatCardsProps> = (
    props: AuroraChatCardsProps,
) => {
    const { message, windowWidth, inHistory } = props;
    const { content, references, thumbUp, thumbDown, isHistory } = message;
    const threadId = useAppSelector((store) => store.thread.selectedThreadId);
    const styles = useStyles();
    const cardPreviewRef = useRef<HTMLDivElement>(null);

    const [isChatHistory, setHistory] = React.useState(inHistory);
    // State to control the visibility of the popover
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

    const openPopover = useCallback(() => {
        setIsPopoverOpen(true);
    }, []);

    const closePopover = useCallback(() => {
        setIsPopoverOpen(false);
    }, []);

    React.useEffect(() => {
        setHistory(inHistory);
    }, [inHistory]);

    const renderContent = (content: ChatResponseContentApiResource[]) => {
        return content.map(
            (item: ChatResponseContentApiResource, index: number) => {
                return (
                    <MarkdownViewer
                        key={index}
                        content={item.value}
                        windowWidth={windowWidth}
                        inAuroraMessage={true}
                    />
                );
            },
        );
    };

    const renderReferences = (references?: References[]) => {
        if (references && references.length > 0 && references[0].name !== '') {
            return (
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '20px',
                    }}
                >
                    {references.map((item, index) => {
                        if (!threadId) {
                            return;
                        }
                        const attachedFile: AttachedFile = {
                            id: item.attachmentId ?? 0,
                            threadId: threadId ?? 0,
                            name: item.name,
                            contentType: '',
                            contentUrl: item.contentUrl,
                            url: item.url ?? undefined,
                            type: item.type ?? undefined,
                            role: item.role ?? undefined,
                        };
                        console.info('attachedFile', attachedFile);
                        if (item.role == 'Aurora') {
                            if (item.type === 'Image') {
                                return (
                                    <ImageThumbnail
                                        key={item.attachmentId}
                                        threadId={threadId ?? undefined}
                                        attachmentId={item.attachmentId ?? 0}
                                        name={item.name}
                                        url={item.url ?? undefined}
                                        role={item.role}
                                        isMessage
                                    />
                                );
                            }
                            return (
                                <FileCard
                                    index={index}
                                    threadId={threadId}
                                    name={item.name}
                                    url={item.url ?? undefined}
                                    id={item.attachmentId ?? undefined}
                                    role={item.role}
                                    disableDismissButton
                                />
                            );
                        }
                    })}
                </div>
            );
        }
    };

    return (
        <Card
            className={styles.card}
            style={{
                width: '100%',
            }}
        >
            <CardHeader
                image={
                    <img
                        src="/logo192.png"
                        alt="Aurora"
                        style={{ width: 40, height: 40, borderRadius: '50%' }}
                    />
                }
                header={
                    <Body1 style={{ marginTop: '10px' }}>
                        <b>Aurora</b>
                    </Body1>
                }
                action={
                    isChatHistory ? (
                        <Popover open={isPopoverOpen}>
                            <PopoverTrigger>
                                <Button
                                    appearance="transparent"
                                    icon={<HistoryFilled />}
                                    style={{
                                        marginRight: '10px',
                                    }}
                                    onMouseEnter={openPopover}
                                    onMouseLeave={closePopover}
                                />
                            </PopoverTrigger>
                            <PopoverSurface
                                tabIndex={-1}
                                style={{
                                    backgroundColor:
                                        'var(--colorNeutralBackground1)',
                                    fontSize: '12px',
                                    paddingTop: '5px',
                                    paddingBottom: '5px',
                                }}
                            >
                                Included in history
                            </PopoverSurface>
                        </Popover>
                    ) : null
                }
            />

            <CardPreview
                className={styles.CardPreview}
                ref={cardPreviewRef}
                style={{
                    paddingRight: windowWidth < 900 ? '35px' : '60px',
                    paddingLeft: windowWidth < 900 ? '20px' : '60px',
                }}
            >
                {renderContent(content)}
                <div style={{ height: '12px' }} />
                {renderReferences(references)}
            </CardPreview>

            <Divider></Divider>
            {/* Call resources component */}
            <ChatCardResources
                threadId={message.threadId}
                messageId={message.id}
                references={
                    references?.filter((r) => r.role !== 'Aurora') ?? []
                }
                thumbUp={thumbUp}
                thumbDown={thumbDown}
                isHistory={isHistory}
            />
        </Card>
    );
};

export default AuroraChatCardsComponent;
