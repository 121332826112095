import {
    Button,
    Switch,
    Text,
    Card,
    Divider,
    makeStyles,
    shorthands,
    tokens,
    Tooltip,
    mergeClasses,
    ProgressBar,
} from '@fluentui/react-components';
import { Sparkle20Regular } from '@fluentui/react-icons';
import React, { useCallback } from 'react';

import { Skill } from '../../Models/Skill';
import ChildSkill from '../ChildSkill';

type SkillCardProps = {
    skillData: Skill;
    showSwitch: boolean;
    handleSkillSelection: (
        id: number,
        skillName: string,
        isChild: boolean,
    ) => void;
    cardWidth: string;
    cardHeight?: string;
    isUpdating?: boolean;
};

const useStyles = makeStyles({
    card: {
        paddingBottom: '40px',
        height: 'min-content',
        ...shorthands.padding('10px', '10px', '10px', '10px'),
    },
    divider: {
        ...shorthands.margin('15px', '0', '10px', '0'),
    },
    settingsButton: {
        ...shorthands.padding('0'),
    },
    backgroundColorSidebar: {
        backgroundColor: tokens.colorNeutralBackground1,
    },
    backgroundColorMenu: {
        backgroundColor: tokens.colorNeutralBackground4,
    },
});

//Returns true if the skill has child skills
const hasChildSkills = (skill: Skill): boolean => {
    return skill.childSkills.length > 0;
};

const SkillCard: React.FC<SkillCardProps> = (props: SkillCardProps) => {
    //State for childSKill menu visibility
    const [childSkillsVisible, setChildSkillsVisible] = React.useState(false);
    const [showProgressBar, setShowProgressBar] = React.useState(false);
    const { cardWidth, cardHeight } = props;
    const styles = useStyles();

    React.useEffect(() => {
        if (!props.isUpdating) {
            setShowProgressBar(false);
        }
    }, [props.isUpdating]);

    const cardClassName = mergeClasses(
        styles.card,
        props.showSwitch
            ? styles.backgroundColorSidebar
            : styles.backgroundColorMenu,
    );
    //Sorted child skills array
    const sortedChildren = [...props.skillData.childSkills].sort(
        (a, b) => a.orderBy - b.orderBy,
    );
    const handleSkillSwitchChange = useCallback(() => {
        setShowProgressBar(true);
        props.handleSkillSelection(
            props.skillData.id,
            props.skillData.name,
            false,
        );
    }, [props]);

    const handleSettingsClick = useCallback(
        () => setChildSkillsVisible(!childSkillsVisible),
        [childSkillsVisible],
    );
    return (
        <Card
            className={cardClassName}
            style={{ width: cardWidth, minHeight: cardHeight }}
        >
            <ProgressBar
                value={showProgressBar ? undefined : 1}
                thickness="large"
                style={{
                    borderRadius: '4px',
                    marginTop: '1px',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: showProgressBar ? 'block' : 'none',
                }}
            />
            <div className="SkillCard">
                <div className="CardHeader">
                    <div className="CardTitle">
                        <Text weight="bold">{props.skillData.name}</Text>
                        {props.skillData.isSpecial && (
                            <Tooltip
                                content="Special Skill"
                                relationship="label"
                            >
                                <Sparkle20Regular
                                    style={{
                                        minWidth: '30px',
                                        maxWidth: '30px',
                                        minHeight: '25px',
                                    }}
                                />
                            </Tooltip>
                        )}
                    </div>
                    {props.showSwitch && (
                        <Switch
                            checked={props.skillData.isSelected}
                            name={props.skillData.name + '-switch'}
                            onChange={handleSkillSwitchChange}
                        ></Switch>
                    )}
                </div>
                <div className="CardBody">
                    <Text align="start">{props.skillData.description}</Text>
                </div>
                <div className="CardFooter">
                    {hasChildSkills(props.skillData) && (
                        <Divider className={styles.divider}></Divider>
                    )}
                    {hasChildSkills(props.skillData) && (
                        <Button
                            className={styles.settingsButton}
                            appearance="transparent"
                            onClick={handleSettingsClick}
                        >
                            Settings
                        </Button>
                    )}
                    {childSkillsVisible &&
                        sortedChildren.map((childSkill) => (
                            <ChildSkill
                                key={childSkill.id}
                                skillData={childSkill}
                                handleChildSkillSelection={
                                    props.handleSkillSelection
                                }
                            />
                        ))}
                </div>
            </div>
        </Card>
    );
};

export default SkillCard;
