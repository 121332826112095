import {
    DrawerBody,
    DrawerHeader,
    InlineDrawer,
    tokens,
    DrawerHeaderTitle,
    Drawer,
    DrawerProps,
    Link,
    Image,
} from '@fluentui/react-components';
import React, { useRef } from 'react';

import { useIntersection } from 'Hooks/useIntersection';
import { AdminPages } from 'Pages/Admin';

import UserProfile from '../UserProfileContainer';
import '../../Styles/Styles.css';

type LeftSidebarProps = {
    setAdminPage: (page: AdminPages) => void;
};
type DrawerType = Required<DrawerProps>['type'];

export const AdminLeftSidebar: React.FC<LeftSidebarProps> = ({
    setAdminPage,
}: LeftSidebarProps) => {
    const sidebarRef = useRef<HTMLDivElement>(null);
    const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);

    const [type, setType] = React.useState<DrawerType>('inline');

    const drawerBodyRef = useRef<HTMLDivElement>(null);
    const bottomRef = useRef<HTMLDivElement>(null);
    const isBottomVisible = useIntersection(bottomRef, '0px');

    return (
        <Drawer
            type={type}
            separator
            position="start"
            ref={sidebarRef}
            open={true}
            style={{
                height: '100vh',
                background: tokens.colorNeutralBackground3,
                width: '20%', // can change size of drawer
                overflow: 'visible', // needs this otherwise the main chat area will be on top
                minWidth: '20%',
            }}
        >
            <InlineDrawer
                separator
                position={'start'}
                open={true}
                style={{
                    background: tokens.colorNeutralBackground3,
                    height: '100%',
                    width: '100%',
                }}
            >
                <DrawerHeader>
                    <DrawerHeaderTitle>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Image
                                alt="Aurora Logo"
                                src="/logo192.png"
                                shape="circular"
                                height={30}
                            />
                            <div style={{ paddingLeft: '12px' }}>
                                Admin Dashboard
                            </div>
                        </div>
                    </DrawerHeaderTitle>
                </DrawerHeader>

                <DrawerBody
                    className="custom-scrollbar"
                    style={{
                        background: 'transparent',
                        marginBottom: '55px',
                    }}
                    ref={drawerBodyRef}
                >
                    <div
                        style={{
                            marginTop: '50px',
                            alignSelf: 'flex-start',
                            width: '-webkit-fill-available',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Link onClick={() => setAdminPage(AdminPages.Banner)}>
                            Banner
                        </Link>
                        <div style={{ height: '16px' }} />
                        {/* 
                        To Do:
                        <Link
                            onClick={() => setAdminPage(AdminPages.LLMSettings)}
                        >
                            LLM Settings
                        </Link> */}
                        <div ref={bottomRef} />
                    </div>
                    <div className="LeftSidebarFooter">
                        <UserProfile />
                    </div>
                </DrawerBody>
            </InlineDrawer>
        </Drawer>
    );
};
