import {
    makeStyles,
    shorthands,
    Text,
    tokens,
    Subtitle1,
    Card,
    CardHeader,
    Image,
    Caption1,
} from '@fluentui/react-components';
import * as React from 'react';
import '../../Styles/ChatCards.Module.css';
import { useCallback } from 'react';

import {
    useGetPromptsQuery,
    useGetThreadDetailsQuery,
} from 'Services/API/Aurora';

import useAppSelector from '../../Hooks/useAppSelector';
import { StarterPrompt } from '../../Models/StarterPrompt';
import LoadNewChatCardContainer from '../LoadNewChatCardsContainer';
type ChatCardProps = {
    sendStarter: (prompt: StarterPrompt) => Promise<void>;
    threadId: number | null;
};

const useStyles = makeStyles({
    main: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)', // This creates three columns
        width: '100%', // Take full width
        '@media(max-width: 1000px)': {
            gridTemplateColumns: 'repeat(2, 1fr)', // Two columns for medium/small screens
        },
    },
    card: {
        width: '95%',

        height: '130px',
        marginBottom: '12px',
        marginRight: '6px',
        marginLeft: '6px',
        paddingBottom: '8px',

        backgroundColor: tokens.colorNeutralBackground4,
        ':hover': {
            backgroundColor: tokens.colorNeutralBackground3,
            cursor: 'pointer',
        },
    },

    title: {
        ...shorthands.margin(0, 0, '15px'),
        textAlign: 'center', // center align the title text
        marginBottom: '40px',
    },

    text: {
        ...shorthands.margin(0),
    },

    skillName: {
        ...shorthands.margin(0),
        justifyContent: 'flex-end',
    },
});

const Title = ({ children }: React.PropsWithChildren<{}>) => {
    const styles = useStyles();

    return (
        <Subtitle1 as="h4" block className={styles.title}>
            {children}
        </Subtitle1>
    );
};
const NewChatCardContainer: React.FC<ChatCardProps> = (
    props: ChatCardProps,
) => {
    const styles = useStyles();

    const threadId = useAppSelector((store) => store.thread.selectedThreadId);
    const { data: threadDetails } = useGetThreadDetailsQuery(threadId ?? 0, {
        skip: !threadId,
    });
    const files = threadDetails?.attachments;
    //Starter Prompts
    const { data: prompts } = useGetPromptsQuery(Boolean(files?.length));

    //Helper for sending messages
    const sendMessage = useCallback(
        async (prompt: StarterPrompt): Promise<void> => {
            props.sendStarter(prompt);
        },
        [props],
    );

    if (!prompts?.length) {
        return <LoadNewChatCardContainer />;
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center', // Center horizontally
                width: '100%', // Take full width to allow centering
                height: '100vh', // Take full height to allow centering
            }}
        >
            <Image
                alt="Aurora Logo"
                src="/logo192.png"
                shape="circular"
                height={70}
                width={70}
                style={{ marginBottom: '20px' }}
            />

            <Title>How can I help you today?</Title>
            <div className={styles.main}>
                {[...prompts]
                    .sort((a, b) => a.order - b.order)
                    .map((prompt) => (
                        <Card
                            key={prompt.id}
                            className={styles.card}
                            onClick={async () => await sendMessage(prompt)}
                            style={{
                                borderRadius: '10px',
                            }}
                        >
                            <CardHeader
                                header={
                                    <Text weight="semibold">
                                        {prompt.promptTitle}
                                    </Text>
                                }
                            />
                            <p className={styles.text}>
                                {prompt.promptMessage}
                            </p>
                            <Caption1
                                align="end"
                                style={{
                                    position: 'absolute', // Position Caption1 absolutely
                                    bottom: '5px', // Align Caption1 to the bottom
                                    right: '10px', // Align Caption1 to the right
                                }}
                            >
                                {/** Display the skill name */}
                            </Caption1>
                        </Card>
                    ))}
            </div>
        </div>
    );
};

export default NewChatCardContainer;
