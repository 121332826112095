import {
    Dialog,
    DialogSurface,
    DialogContent,
    DialogBody,
    Button,
    Text,
    makeStyles,
    shorthands,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import 'Styles/SettingsMenu.Module.css';
import React, { useCallback, useEffect, useState } from 'react';

import LoadingSettingsMenuDefaults from 'Components/LoadingSettingsMenuDefaults';
import SettingsMenuDefaults from 'Components/SettingsMenuDefaults/SettingsMenuDefaults';
import useAppSelector from 'Hooks/useAppSelector';
import { ISkill, Skill } from 'Models/Skill';
import { User } from 'Models/User';
import {
    useGetThreadDetailsQuery,
    useGetUserQuery,
    useGetUserSkillsQuery,
    usePatchThreadChatHistoryLimitMutation,
    usePatchThreadSkillsMutation,
    usePatchUserMutation,
    usePatchUserSkillsMutation,
} from 'Services/API/Aurora';
import {
    updateSettingsDialogOpen,
    updateNewChatDialogOpenTrue,
    unselectAllChatThreadsAction,
} from 'Services/StateManagement/Actions';
import { updateStore } from 'Services/StateManagement/Utils';

interface SettingsMenuContainerProps {}

//Styles
const useStyles = makeStyles({
    dialogSurface: {
        ...shorthands.padding('0px'),
        '@media(max-width: 850px)': {
            // for screens smaller than 850px
            minWidth: '100%', // use a fixed width
            height: '100%',
        },
        '@media(min-width: 851px)': {
            // for screens larger than 850px
            minWidth: '700px', // use a fixed width
            height: '70%',
        },
    },
    dialogBody: {
        height: '100%',
        maxHeight: '100%',
        ...shorthands.padding('0px'),
    },
    dialogContent: {
        height: '100%',
        ...shorthands.padding('0px'),
    },
});

const SettingsMenuContainer: React.FC<SettingsMenuContainerProps> = () => {
    const styles = useStyles();
    const isDialogOpen = useAppSelector(
        (store) => store.userInterface.settingsDialogOpen,
    );

    const { data: user, isLoading: isLoadingUserData } = useGetUserQuery();
    const { data: userSkills, isLoading: isLoadingSkillsData } =
        useGetUserSkillsQuery();

    const threadId = useAppSelector((store) => store.thread.selectedThreadId);
    const { data: selectedThread } = useGetThreadDetailsQuery(threadId ?? 0, {
        skip: !threadId,
    });

    const [userPreferencesForm, setUserPreferencesForm] = useState<
        User | undefined
    >(user);
    const [skillsForm, setSkillsForm] = React.useState<Skill[]>([]);

    useEffect(() => {
        if (!isLoadingUserData && user) {
            setUserPreferencesForm(user);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingUserData]);

    useEffect(() => {
        if (!isLoadingSkillsData && userSkills) {
            setSkillsForm(userSkills);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingSkillsData]);

    const [updateUser] = usePatchUserMutation();
    const [updateSkills] = usePatchUserSkillsMutation();
    const [patchThreadSkills] = usePatchThreadSkillsMutation();
    const [patchThreadChatLimit] = usePatchThreadChatHistoryLimitMutation();

    //Get selected thread from the store

    //State for storing the skills to be sent in the API request
    const [skillsToSend, setSkillsToSend] = React.useState<Skill[]>([]);

    //State for save button usability
    const [areThereUnsavedSkillsChanges, setAreThereUnsavedSkillsChanges] =
        React.useState<boolean>(false);
    const [isLoadingSave, setIsLoadingSave] = React.useState<boolean>(false);
    const [isSaved, setIsSaved] = React.useState<boolean>(false);

    const areThereUnsavedUserChanges =
        JSON.stringify(user) !== JSON.stringify(userPreferencesForm);

    const isAtLeastOneSkillSelected = Boolean(
        skillsForm.find((skill) => skill.isSelected),
    );
    const shouldEnableSaveButton =
        (areThereUnsavedSkillsChanges ||
            areThereUnsavedUserChanges ||
            isLoadingSave) &&
        isAtLeastOneSkillSelected;

    //Handlers
    const handleSave = useCallback(async () => {
        if (!userPreferencesForm) {
            return;
        }
        setIsLoadingSave(true);

        //Call API to update
        await updateSkills(skillsToSend);
        await updateUser(userPreferencesForm);

        //If a new chat is open, update it to follow the new defaults
        if (selectedThread && selectedThread.messageCount === 0) {
            patchThreadSkills({
                threadId: threadId ?? 0,
                setSkills: skillsForm.map((s) => ({
                    skillid: s.id,
                    isSelected: s.isSelected,
                })),
                setLocalSkills: skillsForm.map(
                    (s): ISkill => ({
                        id: s.id,
                        isSelected: s.isSelected,
                        name: s.name,
                        description: s.description,
                        isSpecial: s.isSpecial,
                        childSkills: s.childSkills,
                        orderBy: s.orderBy,
                    }),
                ),
            });
            patchThreadChatLimit({
                threadId: threadId ?? 0,
                chatHistoryLimit: userPreferencesForm.chatHistoryLimit,
            });
            updateStore(updateNewChatDialogOpenTrue());
            updateStore(unselectAllChatThreadsAction());
        }
        // Set isSaved to true when the save is successful
        setIsSaved(true);
        setTimeout(() => setIsSaved(false), 2000);
        //Close the dialog
        updateStore(updateSettingsDialogOpen());

        setIsLoadingSave(false); // Set loading to false when the process is complete or if an error occurs
    }, [
        patchThreadChatLimit,
        patchThreadSkills,
        selectedThread,
        skillsForm,
        skillsToSend,
        threadId,
        updateSkills,
        updateUser,
        userPreferencesForm,
    ]);

    const handleDialogDismiss = useCallback(
        () => updateStore(updateSettingsDialogOpen()),
        [],
    );
    return (
        <>
            <Dialog
                modalType="modal"
                open={isDialogOpen}
                onOpenChange={handleDialogDismiss}
            >
                <DialogSurface className={styles.dialogSurface}>
                    <DialogBody className={styles.dialogBody}>
                        <DialogContent className={styles.dialogContent}>
                            {!isLoadingUserData &&
                            !isLoadingSkillsData &&
                            userPreferencesForm ? (
                                <div className="SettingsMenuContainer">
                                    <div className="SettingsMenuTopNav">
                                        <Text size={500}>Settings</Text>
                                        <Button
                                            icon={<Dismiss24Regular />}
                                            appearance="subtle"
                                            onClick={handleDialogDismiss}
                                        />
                                    </div>
                                    <SettingsMenuDefaults
                                        userPreferences={userPreferencesForm}
                                        setUserPreferences={
                                            setUserPreferencesForm
                                        }
                                        skills={skillsForm}
                                        initialSkills={userSkills ?? []}
                                        setSkillsToSend={setSkillsToSend}
                                        setSkills={setSkillsForm}
                                        setAreThereUnsavedSkillsChanges={
                                            setAreThereUnsavedSkillsChanges
                                        }
                                    />

                                    <div className="SettingsMenuFooterNav">
                                        <Button
                                            onClick={handleSave}
                                            appearance="primary"
                                            disabled={!shouldEnableSaveButton}
                                        >
                                            {isSaved
                                                ? 'Saved'
                                                : isLoadingSave
                                                  ? 'Saving...'
                                                  : 'Save'}
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                <div className="SettingsMenuContainer">
                                    <div className="SettingsMenuTopNav">
                                        <Text size={500}>Settings</Text>
                                        <Button
                                            icon={<Dismiss24Regular />}
                                            appearance="subtle"
                                            onClick={handleDialogDismiss}
                                        />
                                    </div>

                                    <LoadingSettingsMenuDefaults />
                                    <div className="SettingsMenuFooterNav">
                                        <Button
                                            onClick={handleSave}
                                            appearance="primary"
                                            disabled={!shouldEnableSaveButton}
                                        >
                                            {isSaved
                                                ? 'Saved'
                                                : isLoadingSave
                                                  ? 'Saving...'
                                                  : 'Save'}
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </DialogContent>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </>
    );
};

export default SettingsMenuContainer;
