import { ZodSchema } from 'zod';

import { AttachedFile } from 'Models/ChatThread';

export function validateDataAgainstSchema<T>(
    data: T,
    schema: ZodSchema<T>,
): boolean {
    try {
        schema.parse(data);
        return true;
    } catch (error) {
        console.error('error in parsing', error);
        return false;
    }
}

export const documentExtensions = [
    'pdf',
    'docx',
    'pptx',
    'xlsx',
    'csv',
    'txt',
    'json',
    'html',
];

export const imageExtensions = ['jpeg', 'jpg', 'png'];

export const allowedExtensions = [...documentExtensions, ...imageExtensions];

export function getFileIcon(fileName: string, url?: string | null) {
    let logoURL;
    if (fileName) {
        if (fileName.endsWith('.pptx') || url?.endsWith('.pptx')) {
            logoURL = 'pptx.png';
        } else if (fileName.endsWith('.docx') || url?.endsWith('.docx')) {
            logoURL = 'docx.png';
        } else if (
            fileName.endsWith('.xlsx') ||
            url?.endsWith('.xlsx') ||
            fileName.endsWith('.csv') ||
            url?.endsWith('.csv')
        ) {
            logoURL = 'xlsx.png';
        } else if (fileName.endsWith('.pdf') || url?.endsWith('.pdf')) {
            logoURL = 'pdf.png';
        } else if (fileName.endsWith('.json') || url?.endsWith('.json')) {
            logoURL = 'json.png';
        } else if (
            fileName.endsWith('.jpeg') ||
            fileName.endsWith('.jpg') ||
            fileName.endsWith('.png')
        ) {
            logoURL = 'jpeg.png';
        } else if (
            url?.endsWith('.asp') ||
            url?.endsWith('.aspx') ||
            url?.endsWith('.html')
        ) {
            logoURL = 'spo.png';
        } else {
            logoURL = 'document.png';
        }
        return logoURL;
    }
    return 'document.png';
}

export const fileToText = async (file: File): Promise<string> => {
    const reader = new Blob([file]);
    return (await reader.text()).toString();
};

export const readFileAsBase64 = async (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            resolve(event.target?.result?.toString().split(',')[1] as string);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });

export const readFileAsBase64WithHeader = async (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            resolve((event.target?.result ?? '').toString());
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });

// Formats date as "09/16/2024, 10:45:26 AM EDT"
export const formatDateTimeHumanReadable = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
        timeZoneName: 'short',
    };
    return new Intl.DateTimeFormat('en-US', options).format(date);
};

export const getFileExtension = (fileName: string): string => {
    const separatedFilename = fileName.split('.');
    return separatedFilename[separatedFilename.length - 1].toLowerCase();
};

export const validateFile = (
    fileName: string,
    attachments?: AttachedFile[] | null,
    url?: string,
): void => {
    // Is extension supported
    const extension = getFileExtension(fileName);
    if (!allowedExtensions.includes(extension)) {
        throw new Error('Unsupported file type.');
    }

    // if no other files are attached, exit
    const existingFile = attachments?.[0];
    if (!existingFile) {
        return;
    }

    // don't allow attaching the same file twice
    if (existingFile.url && existingFile.url === url) {
        throw new Error(
            'This file has already been added to the conversation.',
        );
    }

    // don't allow a document and an image to be attached at the same time
    const existingFileExtension = getFileExtension(existingFile?.name);
    if (
        isDocumentExtension(existingFileExtension) &&
        isImageExtension(extension)
    ) {
        throw new Error('Documents and images cannot be processed together.');
    }

    if (
        isImageExtension(existingFileExtension) &&
        isDocumentExtension(extension)
    ) {
        throw new Error('Documents and images cannot be processed together.');
    }
};

export const isDocumentExtension = (extension: string): boolean =>
    documentExtensions.includes(extension);

export const isImageExtension = (extension: string): boolean =>
    imageExtensions.includes(extension);

export const isImageFile = (fileName: string): boolean =>
    imageExtensions.includes(getFileExtension(fileName));

export const openNewTab = (): Window | null => {
    const image_tab = window.open('', '_blank');
    image_tab?.document.write(`
            <html>
                <head>
                </head>
                <body>
                <div id="loading_message">Retrieving file...</div>
                </body>
            </html>
        `);
    return image_tab;
};

export const openImageInTab = (url: string, image_tab: Window | null) => {
    if (image_tab) {
        const message = image_tab.document.getElementById('loading_message');
        message?.remove();

        const image = image_tab.document.createElement('img');
        image.src = url;
        image.style.maxWidth = '99vw';
        image_tab.document.body.appendChild(image);
    }
};

export const downloadFileFromUrl = (url: string, fileName: string) => {
    const hiddenLink = document.createElement('a');
    hiddenLink.href = url;
    hiddenLink.target = '_blank';
    hiddenLink.download = fileName;
    hiddenLink.click();
};
