import { z } from 'zod';

//User zod schema
export const UserModelSchema = z.object({
    id: z.string().uuid(),
    chatHistoryLimit: z.number().int().min(0).max(15),
    theme: z.enum(['Light', 'Dark']),
    beta: z.boolean(),
});

//Export user model
export type User = z.infer<typeof UserModelSchema>;
