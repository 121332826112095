import {
    ToastIntent,
    useId,
    useToastController,
} from '@fluentui/react-components';
import { ReactNode, useEffect } from 'react';

type Params = {
    toast: ReactNode;
    isEnabled?: boolean;
    intent?: ToastIntent;
};

type Return = {
    toastId: string;
    trigger: () => void;
};

export const useGlobalToast = (params: Params): Return => {
    const { isEnabled, toast, intent = 'error' } = params;
    //Toaster
    const toastId = useId('toast');
    const { dispatchToast } = useToastController(toastId);
    const triggerToast = () => dispatchToast(toast, { timeout: 5000, intent });

    useEffect(() => {
        if (isEnabled) {
            triggerToast();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEnabled]);

    return { toastId, trigger: triggerToast };
};
