import { ERROR_MESSAGE } from 'Constants';
import { User } from 'Models/User';
import { setToastMessage } from 'Services/StateManagement/Actions';

import { AuroraEndpointBuilder } from '..';

export const getUser = (builder: AuroraEndpointBuilder) =>
    builder.query<User, void>({
        query: () => '/v1/Users/GetUser',
        transformResponse: (response: { data: User }) => {
            return response.data;
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
            try {
                await queryFulfilled;
            } catch {
                dispatch(
                    setToastMessage({
                        title: ERROR_MESSAGE.UserGet,
                        body: '',
                        position: 'top',
                    }),
                );
            }
        },
        providesTags: ['User'],
    });
