import {
    Button,
    Checkbox,
    Image,
    Input,
    makeStyles,
    shorthands,
    Subtitle1,
} from '@fluentui/react-components';
import { useState } from 'react';

import { AdminBanner } from 'Containers/AdminBanner';
import { AdminLeftSidebar } from 'Containers/AdminLeftSidebar';
import {
    useCreateBannerMutation,
    useGetBannersQuery,
    usePutBannerMutation,
} from 'Services/API/Aurora';

type Props = {};

const useStyles = makeStyles({
    root: {
        display: 'flex',
        // backgroundColor: tokens?.colorNeutralBackground2,
        //...shorthands.overflow('hidden'),
        overflowY: 'hidden',
        height: '100vh',
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding('16px'),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    darkTintOverlay: {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        backgroundColor: 'rgba(0, 0, 0, .7)',
        zIndex: '1',
    },
});

export enum AdminPages {
    Banner = 'banner',
    LLMSettings = 'llmSettings',
}

export const Admin: React.FC = () => {
    const styles = useStyles();
    const [adminPage, setAdminPage] = useState(AdminPages.Banner);
    const renderAdminPage = () => {
        switch (adminPage) {
            case AdminPages.Banner:
                return <AdminBanner />;
            case AdminPages.LLMSettings:
                return <></>;
        }
    };
    return (
        <div className={styles.root}>
            <AdminLeftSidebar setAdminPage={setAdminPage} />

            {renderAdminPage()}
        </div>
    );
};
