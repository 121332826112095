import {
    Button,
    Card,
    Checkbox,
    Image,
    Input,
    Radio,
    RadioGroup,
    Skeleton,
    SkeletonItem,
    Spinner,
    Subtitle1,
    Table,
    TableBody,
    TableRow,
    Text,
    tokens,
} from '@fluentui/react-components';
import { DeleteFilled, DeleteRegular } from '@fluentui/react-icons';
import { useState } from 'react';

import SkeletonChatCards from 'Containers/SkeletonChatCards';
import {
    useCreateBannerMutation,
    useDeleteBannerMutation,
    useGetActiveBannerQuery,
    useGetBannersQuery,
    usePutBannerMutation,
    useSetActiveBannerIdMutation,
} from 'Services/API/Aurora';

type Props = {};

export const AdminBanner: React.FC = () => {
    const [createBanner] = useCreateBannerMutation();
    const [deleteBanner] = useDeleteBannerMutation();
    const { data: banners, isLoading: isLoadingBanners } =
        useGetBannersQuery(1);
    const { data: activeBanner, isLoading: isLoadingActiveBanner } =
        useGetActiveBannerQuery();
    const [setActiveBannerId] = useSetActiveBannerIdMutation();
    const [newBannerMessage, setNewBannerMessage] = useState('');
    const [isDeleteHovered, setIsDeleteHovered] = useState(-1);

    if (isLoadingBanners || isLoadingActiveBanner) {
        return (
            <div
                style={{
                    width: '100%', // Take full width to allow centering
                    height: '100%', // Take full height to allow centering
                    display: 'flex',
                    marginTop: '100px',
                    justifyContent: 'center',
                }}
            >
                <Card
                    style={{
                        width: '500px',
                        height: '196px',
                        opacity: 0.8,
                        backgroundColor: 'var(--colorNeutralBackground2)',
                    }}
                >
                    <Skeleton width="500px">
                        <SkeletonItem
                            style={{
                                backgroundColor:
                                    'var(--colorNeutralBackground1)',
                            }}
                        />
                        {[...Array(6)].map(() => (
                            <SkeletonItem
                                style={{
                                    backgroundColor:
                                        'var(--colorNeutralBackground1)',
                                    marginTop: '10px',
                                }}
                            />
                        ))}
                    </Skeleton>
                </Card>
            </div>
        );
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start', // Center horizontally
                width: '100%', // Take full width to allow centering
                height: '100%', // Take full height to allow centering
                padding: '20px',
                overflow: 'scroll',
            }}
        >
            <Subtitle1 as="h4" block>
                Banners
            </Subtitle1>
            <Table style={{ maxWidth: '500px' }}>
                <TableBody>
                    <RadioGroup value={activeBanner?.id?.toString() ?? 'none'}>
                        {banners?.items.map((banner, index) => (
                            <TableRow style={{ width: '100%' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                    }}
                                >
                                    <Radio
                                        value={banner.id.toString()}
                                        label={
                                            <>
                                                {banner.message}
                                                {activeBanner?.id?.toString() ===
                                                banner.id.toString() ? (
                                                    <Text
                                                        style={{
                                                            color: tokens.colorBrandForeground1,
                                                        }}
                                                    >
                                                        {' '}
                                                        (Live)
                                                    </Text>
                                                ) : (
                                                    ''
                                                )}
                                            </>
                                        }
                                        onChange={() => {
                                            const userConfirmed = confirm(
                                                'This will change the live banner, are you sure you want to continue?',
                                            );
                                            if (!userConfirmed) {
                                                return;
                                            }

                                            setActiveBannerId({
                                                id: banner.id,
                                                message: banner.message,
                                            });
                                        }}
                                        style={{ width: '80%' }}
                                    />
                                    <Button
                                        icon={
                                            isDeleteHovered === index ? (
                                                <DeleteFilled />
                                            ) : (
                                                <DeleteRegular />
                                            )
                                        }
                                        onMouseEnter={() =>
                                            setIsDeleteHovered(index)
                                        }
                                        onMouseLeave={() =>
                                            setIsDeleteHovered(-1)
                                        }
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            if (
                                                banner.id === activeBanner?.id
                                            ) {
                                                alert(
                                                    'Cannot delete active banner. Select another banner or "None" and try again.',
                                                );
                                                return;
                                            }
                                            const userConfirmed = confirm(
                                                'Are you sure you want to permanently delete this banner?',
                                            );
                                            if (!userConfirmed) {
                                                return;
                                            }
                                            deleteBanner({
                                                id: banner.id,
                                            });
                                        }}
                                        style={{
                                            padding: '5px',
                                            border: 'none',
                                            background: 'transparent',
                                        }}
                                    />
                                </div>
                            </TableRow>
                        ))}
                        <TableRow style={{ width: '100%' }}>
                            <Radio
                                value={'none'}
                                label={<>None</>}
                                onChange={() => {
                                    const userConfirmed = confirm(
                                        'This will change the live banner, are you sure you want to continue?',
                                    );
                                    if (!userConfirmed) {
                                        return;
                                    }

                                    setActiveBannerId({
                                        id: null,
                                        message: '',
                                    });
                                }}
                                style={{ width: '80%' }}
                            />
                        </TableRow>
                    </RadioGroup>
                </TableBody>
            </Table>
            <div
                style={{
                    maxWidth: '500px',
                    width: '500px',
                    display: 'flex',
                    flexFlow: 'column nowrap',
                }}
            >
                <div style={{ height: '24px' }} />
                <Input
                    style={{ marginBottom: '8px' }}
                    value={newBannerMessage}
                    onChange={(e) => setNewBannerMessage(e.target.value)}
                    placeholder="Enter new banner content"
                />
                <Button
                    style={{ width: '150px' }}
                    onClick={() =>
                        createBanner({
                            message: newBannerMessage,
                        })
                    }
                >
                    Create Banner
                </Button>
            </div>
        </div>
    );
};
