import { cloneDeep } from 'lodash';

import { ERROR_MESSAGE } from 'Constants';
import { AttachedFile, ChatResponseApiResource } from 'Models/ChatThread';
import {
    setIsNewThread,
    setToastMessage,
} from 'Services/StateManagement/Actions';

import { auroraApi, AuroraEndpointBuilder } from '..';
import { PostMessageRequest } from '../dto';

export const postMessageRtc = (builder: AuroraEndpointBuilder) =>
    builder.mutation<{ data: ChatResponseApiResource }, PostMessageRequest>({
        //@ts-ignore
        queryFn: async (
            { threadId, message, retryReferenceId },
            { dispatch },
            _extraOptions,
            baseQuery,
        ) => {
            const result = await baseQuery({
                url: `/v1/Chat/SendMessageRtc`,
                method: 'POST',
                body: {
                    threadId,
                    message,
                },
            });
            const socketAddress = (result.data as { data: string }).data;
            if (!socketAddress) {
                return;
            }

            let ws;
            const messageChunks: string[] = [];
            ws = new WebSocket(socketAddress);

            const messageListener = (event: MessageEvent) => {
                messageChunks.push(event.data);
            };
            ws.addEventListener('message', messageListener);

            let resolveDoneStreamingPromise: (arg0: boolean) => void;
            const doneStreamingPromise = new Promise((resolve) => {
                resolveDoneStreamingPromise = resolve;
            });
            let fullMessage!: ChatResponseApiResource;

            const closeListener = () => {
                if (messageChunks.length === 0) {
                    resolveDoneStreamingPromise(false);
                    return;
                }
                fullMessage = JSON.parse(messageChunks.join(''));
                resolveDoneStreamingPromise(true);
            };
            ws.addEventListener('close', closeListener);

            const success = await doneStreamingPromise;
            dispatch(setIsNewThread(false));
            if (!success) {
                return { error: 'Message error.' };
            }

            return {
                data: {
                    data: fullMessage,
                },
            };
        },
        async onQueryStarted(
            { threadId, message, isRetry, retryReferenceId },
            { dispatch, queryFulfilled },
        ) {
            const TEMP_ID = 0 - new Date().getTime();
            let attachmentsFromThreadDetails: AttachedFile[] | null | undefined;
            // optimistically updates thread date to move to the top of list
            const threadUpdateResult = dispatch(
                auroraApi.util.updateQueryData('getThreads', 0, (draft) => {
                    const thread = draft.items.find(
                        (item) => item.id === threadId,
                    );
                    if (thread) {
                        thread.timeStamp = new Date().toISOString();
                        thread.isHidden = false;
                    }
                }),
            );

            // optimistically updates thread details
            dispatch(
                auroraApi.util.updateQueryData(
                    'getThreadDetails',
                    threadId,
                    (draft) => {
                        draft.messageCount = (draft.messageCount ?? 0) + 2;
                        attachmentsFromThreadDetails = cloneDeep(
                            draft.attachments,
                        );
                    },
                ),
            );

            // Optimistically updates the chat message cache with the new user message
            dispatch(
                auroraApi.util.updateQueryData(
                    'getThreadMessages',
                    { threadId, pageNumber: 1 },
                    (draft) => {
                        // skip re-adding message if it's a retry
                        if (isRetry) {
                            return;
                        }
                        // Reset retry buttons
                        draft.items.forEach((item) => {
                            item.didError = false;
                        });
                        const newMessage: ChatResponseApiResource = {
                            id: TEMP_ID,
                            threadId,
                            thumbUp: false,
                            thumbDown: false,
                            isHistory: false,
                            role: 'User',
                            createdAt: new Date().toISOString(),
                            content: [
                                {
                                    type: 'text',
                                    value: message,
                                    language: '',
                                },
                            ],
                            parentId: null,
                            references: attachmentsFromThreadDetails?.map(
                                (attachment, i) => ({
                                    attachmentId: attachment.id,
                                    url: attachment.url ?? null,
                                    name: attachment.name,
                                    location: '',
                                    number: i,
                                }),
                            ),
                            retryReferenceId,
                            retryCount: 0,
                        };
                        draft.items.push(newMessage);

                        const newResponse: ChatResponseApiResource = {
                            id: TEMP_ID + 1,
                            threadId,
                            thumbUp: false,
                            thumbDown: false,
                            isHistory: false,
                            role: 'User',
                            createdAt: new Date().toISOString(),
                            content: [
                                {
                                    type: 'text',
                                    value: '',
                                    language: '',
                                },
                            ],
                            parentId: null,
                            retryReferenceId: null,
                            retryCount: 0,
                            references: [],
                            isLoading: true,
                        };

                        draft.items.push(newResponse);
                        draft.totalItemCount = (draft.totalItemCount ?? 0) + 1;
                        draft.pageCount = draft.pageCount ? draft.pageCount : 1;
                    },
                ),
            );

            try {
                const result = await queryFulfilled;
                dispatch(
                    auroraApi.util.updateQueryData(
                        'getThreadMessages',
                        { threadId: threadId, pageNumber: 1 },
                        (draft) => {
                            const auroraMessageIndex = draft.items.findIndex(
                                (item) => item.id === TEMP_ID + 1,
                            );
                            if (auroraMessageIndex >= 0) {
                                draft.items[auroraMessageIndex] =
                                    result.data.data;
                            }
                        },
                    ),
                );
            } catch {
                dispatch(
                    setToastMessage({
                        title: ERROR_MESSAGE.MessagePost,
                        body: '',
                        position: 'bottom',
                    }),
                );

                // Flags the user message if it fails
                dispatch(
                    auroraApi.util.updateQueryData(
                        'getThreadMessages',
                        { threadId, pageNumber: 0 },
                        (draft) => {
                            const userMessage = draft.items.find(
                                (item) => item.id === TEMP_ID,
                            );
                            if (userMessage) {
                                userMessage.didError = true;
                            }
                        },
                    ),
                );
                dispatch(setIsNewThread(false));
                // undoes the optimistic update if the call fails
                threadUpdateResult.undo();
                /**
                 * Alternatively, on failure you can invalidate the corresponding cache tags
                 * to trigger a re-fetch:
                 * dispatch(api.util.invalidateTags(['Messages']))
                 */
            }
        },
        invalidatesTags: ['ThreadDetails'], // to update thread title
    });
