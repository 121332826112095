import { nanoid } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { ERROR_MESSAGE } from 'Constants';
import { setToastMessage } from 'Services/StateManagement/Actions';
import { RootState } from 'Services/StateManagement/store';
import { validateFile } from 'Utils';

import { auroraApi, AuroraEndpointBuilder } from '..';
import {
    PostAttachLocalFileToThreadRequest,
    PostAttachLocalFileToThreadResponse,
} from '../dto';

export const postAttachLocalFileToThread = (builder: AuroraEndpointBuilder) =>
    builder.mutation<
        PostAttachLocalFileToThreadResponse,
        PostAttachLocalFileToThreadRequest
    >({
        query: ({ contentUrl, ...body }) => ({
            url: '/v1/Threads/AttachLocalFileToThread',
            method: 'POST',
            body,
        }),
        transformResponse: (response: {
            data: PostAttachLocalFileToThreadResponse;
        }) => {
            return response.data;
        },
        async onQueryStarted(
            { threadId, fileName },
            { dispatch, queryFulfilled },
        ) {
            // Optimistically updates the thread with the new file
            const TEMP_ID = 0 - new Date().getTime();
            const postResult = dispatch(
                auroraApi.util.updateQueryData(
                    'getThreadDetails',
                    threadId,
                    (draft) => {
                        draft.attachments?.push({
                            name: fileName,
                            id: TEMP_ID,
                            isLoading: true,
                            type: undefined,
                            threadId,
                            contentType: '',
                        });
                    },
                ),
            );

            try {
                const result = await queryFulfilled;
                // Updates cache with file details
                dispatch(
                    auroraApi.util.updateQueryData(
                        'getThreadDetails',
                        threadId,
                        (draft) => {
                            const newFile = draft.attachments?.find(
                                (file) => file.id === TEMP_ID,
                            );
                            if (newFile) {
                                newFile.id = result.data.id ?? null;
                                newFile.isLoading = false;
                            }
                        },
                    ),
                );
            } catch {
                dispatch(
                    setToastMessage({
                        title: ERROR_MESSAGE.FileUpload,
                        body: '',
                        position: 'bottom',
                    }),
                );
                // undoes the optimistic update if the call fails
                postResult.undo();
                /**
                 * Alternatively, on failure you can invalidate the corresponding cache tags
                 * to trigger a re-fetch:
                 * dispatch(api.util.invalidateTags(['Messages']))
                 */
            }
        },
    });
